import React, {Component} from 'react';
import axios from 'axios';
import './login.css';
import {getBackendAdress} from "../tools";
import InputFieldOldSchool from "../____style_definitions/InputFieldOldSchool";
import ButtonOldSchool, {BUTTON_TYPES} from "../____style_definitions/ButtonOldSchool";
import PanelOldSchool from "../____style_definitions/PanelOldSchool";

export default class Login extends Component {

    componentWillReceiveProps(nextProps) {

        this.checkCookie( this.cookie_read() )
    }

    cookie_delete(){
        document.cookie = 'keks=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    cookie_create( username, hash ){
        document.cookie = "user=" + username;
        document.cookie = "keks=" + hash;
    }
    cookie_read(){
        let keks = "";
        document.cookie.split(";").map( (item) => {
            if( item.includes("keks=") ){
                keks = item.replace("keks=","").trim();
            }});
        return keks;
    }

    checkPassword( username, password ){

        var self = this;
        var md5 = require('md5');
        var hash = md5(Math.random());

        axios.get( getBackendAdress( this.props.devMode ) + 'php_login.php?type=validatePassword&username=' + username + '&password=' + password + "&hash=" + hash)
            .then( function (response) {

                self.answerCheckPassword( response, username, hash );
            });
    }
    answerCheckPassword( response, username, hash ){

        if( response.data === true || this.props.devMode === "offline" ){

            this.cookie_create( username, hash );
            this.props.setLoginSuccess();

        } else {

            this.cookie_delete();
        }
    }
    checkCookie( hash ){

        if( hash != undefined ) {

            var self = this;

            axios.get( getBackendAdress( this.props.devMode) + 'php_login.php?type=validdateCookie&hash=' + hash)
                .then(function (response) {

                    self.answerCheckCookie( response );
                });
        }
    }
    answerCheckCookie( response ){

        if (response.data === true) {

            this.props.setLoginSuccess();
        } else {

            this.cookie_delete();
        }
    }


    submitLogin(){

        let username = document.getElementById("username").value;
        let password = document.getElementById("password").value;

        var md5 = require('md5');
        let passwordMD5 = md5(password);

        this.checkPassword( username, passwordMD5 );
    }

    pressEnter( $key ){

        if( $key.key === 'Enter' ){
            this.submitLogin();
        }
    }

    render(){

        return <div className="login_frame">
            <PanelOldSchool width={'500px'}>
                <div className="row">
                    <div className="col-md-4">Benutzername :</div>
                    <div className="col-md-8">
                        <InputFieldOldSchool type="text" id="username" autoComplete="off" autofocus="true" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">Passwort :</div>
                    <div className="col-md-8">
	                    <InputFieldOldSchool type="password" id="password" onKeyPress={ ($self) => this.pressEnter($self)}/>
                    </div>
                </div>
	            <ButtonOldSchool type={BUTTON_TYPES.primary} className={'login_submit'} onClick={ ($self) => this.submitLogin($self)}>Login</ButtonOldSchool>
            </PanelOldSchool>
        </div>
    }
}
/**
 * Created by dag on 24.07.17.
 */
import React, {Component} from 'react';
import Modal from 'react-modal';
import {Button} from 'react-bootstrap/dist/react-bootstrap';
import FormControl from 'react-bootstrap/lib/FormControl';
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import axios from 'axios';
import {getBackendAdress,errorMessage} from '../../../tools';


import './importModal.css';


const customStyles = {
    overlay : {
        position          : 'fixed',
        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
        backgroundColor   : 'rgba(255, 255, 255, 0.2)'
    },
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        borderRadius      : '15px',
    }};


class importModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: '',
            page: '',
            modalIsOpen: true,
            step: 1,
            importFile: ''
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }


    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.props.OnPageChange('finance');
        this.setState({modalIsOpen: false});
    }

    importStep( $step ){
        switch( $step ){
            case 1:
                return this.importStepOne();
            case 2:
                return this.importStepTwo();
            default:
                return null;
        }

    }

    importStepOne(){
        return (
            <div>
                <div className="header">Schritt 1: ziehe die aktuelle Transaktionsdatei</div>
                <div className="description">
                    <div>Du mußt Dir die aktuellste Transaktionsdatei besorgen. Gehe dazu auf die folgende Seite:</div>
                    <a href="https://www.sparkasse-spree-neisse.de/" target="_blank">https://www.sparkasse-spree-neisse.de</a>
                    <br/>
                    <ul>
                        <li>Logge Dich ein.</li>
                        <li>Wähle den Zeitraum ab ...</li>
                        <li>exportiere die Daten als <b>CSV-MT940 Format</b></li>
                        <li>klicke auf weiter</li>
                    </ul>
                </div>
                <div className="dialogaction">
                    <Button
                        bsStyle="primary"
                        onClick={() => { this.setState({ step: 2})}}
                    >weiter</Button>
                </div>
            </div>
        );
    }

    importStepTwo(){
        return (
            <div>
                <div className="header">Schritt 2: importiere aktuelle Transaktionsdatei</div>
                <div className="description">
                    <form>
                        <FormControl
                            type="file"
                            label="Text"
                            onChange={(e) => this.setState({importFile: e.target.files})}
                        />
                    </form>
                </div>
                <div className="dialogaction">
                    <Button
                        onClick={() => this.setState({step: 1})}
                    >zurück</Button>
                    <Button
                        bsStyle="primary"
                        // onClick={this.closeModal}
                        onClick={(e) => {
                            this.closeModal(e);
                            this.import(e);
                            return;
                        }}
                    >importieren</Button>
                </div>
            </div>
        );
    }

    import(){

        var error = false;
        // console.log( this.state.importFile, this.state.importFile.length );

        if( this.state.importFile && this.state.importFile.length === 1 ){

            if( this.state.importFile[0].type !== "text/csv" ) {
                error = true;
                NotificationManager.error('Dateityp ist falsch');
            }

            if( this.state.importFile[0].size <= 0 ){
                error = true;
                NotificationManager.error('Datei ist leer');
            }

        } else {

            error = true;
            NotificationManager.error('keine Datei vorhanden');
        }

        if( ! error ){

            this.sendCSV( this.state.importFile[0] );
        } else {

            NotificationManager.error('Import wurde abgebrochen');
        }
    }

    sendCSV( imagefile ){

        switch( this.props.mode ){

            case 'offline':
                break;

            default:
                var formData = new FormData();
                formData.append( "csvFile", imagefile );

                axios.post( getBackendAdress( this.props.mode ) + 'php_bank.php?action=import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(function(response) {
                    NotificationManager.success('import abgeschlossen');
                }).catch((function (error) {
                    console.log(error);
                    NotificationManager.error('import fehlgeschlagen', 'Finanzen');
                }));
        }
    }


    render(){

        return (
            <div className="modalDialog">

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal">
                    { this.importStep( this.state.step ) }

                </Modal>
            </div>
        );
    }
}


export default importModal;
import React, {Component} from 'react';

import moment from 'moment';
import ReactTooltip from 'react-tooltip';

class OneDay extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isToday: false,
            isWeekend: false,
            showHolidays: this.props.options.showHolidays,
            holidays: [],
            meetings: []
        }
    }

    componentDidMount() {
        this.state = {
            isToday: false,
            isWeekend: false,
            showHolidays: this.props.options.showHolidays,
            holidays: [],
            meetings: []
        }
    }

    componentWillReceiveProps(nextProps){

        this.setState({showHolidays: nextProps.options.showHolidays});
        this.setState({holidays: []});
        this.setState({meetings: []});

        this.setState({holidays: this.markDay(nextProps.year, nextProps.month, nextProps.day, nextProps.holidays)});

        let meetings = [];
        if( nextProps.options.meetings !== undefined ) {
            nextProps.options.meetings.map((item) => {
                if (parseInt(item.year) === nextProps.year) {
                    meetings.push(item);
                }
            });

            if (nextProps.options !== null && nextProps.options.meetings !== null && nextProps.options.meetings !== []) {
                this.setState({
                    meetings:
                        this.markDay(
                            nextProps.year,
                            nextProps.month,
                            nextProps.day,
                            meetings)
                });
            }
        }
    }

    markDay(year, month, day, holidays ){

        let today = moment(new Date());
        let areHolidays = [];

        if( day !== undefined && month !== undefined ){

            this.setState({isToday: today.date() === day && today.month()+1 === month && today.year() === year});

            let weekday = moment(year+'-'+month+'-'+day, 'YYYY-M-D').weekday();
            let isWeekend = day !== null && (weekday === 0 || weekday === 6);
            this.setState({isWeekend: isWeekend});

            if( holidays !== undefined && holidays.length > 0 ) {
                holidays.map((specialDay) => {
                    if(
                        parseInt(specialDay.day) === day &&
                        parseInt(specialDay.month) === month )     {
                        areHolidays.push(specialDay.description);
                    }
                    return 0;
                });
            }
        }
        return areHolidays;
    }

    concatHolidays( holidays ){
        let holiday = [];
        holiday = holidays.map( (item) => {
            return <p key={item}>{item}</p>;
        });

        return holiday;
    }

    addHolidays( holidays, meetings ){
        if( holidays.length === 0 ){
            return meetings;
        } else {
            meetings.map( meeting => {
                holidays.push( meeting );
            });
            return holidays;
        }
    }

    renderDay( classMark, holidays ){
        if( holidays.length > 0 && this.state.showHolidays || this.state.meetings.length > 0 ) {
            return (
                <div className={classMark}>
                    <p data-tip=''
                       data-for={this.props.month + '-' + this.props.day}>{this.props.day === null ? '' : this.props.day}</p>
                    <ReactTooltip id={this.props.month + '-' + this.props.day}>
                        {
                            this.addHolidays(
                                this.concatHolidays(this.state.holidays),
                                this.concatHolidays(this.state.meetings))
                        }
                    </ReactTooltip>
                </div>);
        } else {
            return (
                <div className={classMark}>
                    { this.props.day === null ? '' : this.props.day }
                </div>);
        }
    }

    render() {
        let classMarkWeekend = this.state.isWeekend ? ' cal-weekend' : '';
        let classMark = 'cal-item';
        classMark += this.state.isToday ? ' today' : '';
        classMark += this.state.holidays.length && this.props.options.showHolidays ? ' cal-holiday' : '';
        if( this.state.meetings !== null && this.state.meetings.length > 0 ){
            classMark += ' cal-meeting';
        }

        return (
            <div className={"col-md-1 cal-item" + classMarkWeekend }>
                { this.renderDay( classMark, this.state.holidays ) }
            </div>);
    }
}

export default OneDay;
import React, {Component} from 'react';

import './backups.css'

import axios from 'axios';
import {getBackendAdress, errorMessage} from '../../../tools';


class Backups extends Component {

    constructor(props){
        super(props);

        this.state = {
            backups: {},
            timerInterval: 18000000, // 5h
        };

        this._getBackupStatus( this.props.mode );

        if( this.timerID === undefined ) {
            this.timerID = setInterval(
                () => this._getBackupStatus( this.props.mode ),
                this.state.timerInterval
            );
        }
    }

    componentWillReceiveProps(nextProps) {

        this._getBackupStatus( nextProps.mode );
    }

    componentDidMount(){

        this._getBackupStatus();
    }

    componentWillUnmount() {
        this.timerID = undefined;
        clearInterval(this.timerID);
    }

    _getBackupStatus(){

        var $self = this;

        if( this.timerID !== undefined ) {
            switch( this.props.mode ) {

                case 'offline':
                    axios.get('offline/systembackup.json')

                        .then(function (response) {

                            $self.setState({backups: response.data.backups});
                        }).catch(function (error) {

                        errorMessage(error, 'system');
                    });
                    break;

                default:

                    axios.get( getBackendAdress( this.props.mode ) + 'php_system.php?part=backup')

                        .then(function (response) {

                            $self.setState({backups: response.data.backups});
                        }).catch(function (error) {

                        errorMessage(error, 'system');
                    });
                    break;
            }
        }
    }

    _renderContent(){

        let renderOutput = [
            <div className="row head" key={-1}>
                <div className="col-md-6">Backup</div>
                <div className="col-md-6">Tage</div>
            </div>
        ];


        for( let backupNode in this.state.backups ) {

            renderOutput.push(
                <div className="row data" key={backupNode}>
                    <div className="col-md-6">
                        { backupNode }
                    </div>
                    <div className="col-md-6">
                        { this._renderValidatorList( this.state.backups[backupNode]) }
                    </div>
                </div>
            )
        }

        return renderOutput;
    }

    _renderValidatorList( arrayList ){

        let result = [];

        for( let item in arrayList ){

            for( let gh in  arrayList[item] ){
                if( arrayList[item][gh] === 1 ) {
                    result.push(
                        <a href="#" className="glyphicon glyphicon-ok-sign"
                           key={gh}
                             style={{color: 'darkgreen', float: 'left', paddingRight: '5px'}}
                             data-toogle="tooltip" title={"vor "+gh+" Tage(n)"} />);
                } else {
                    result.push(
                        <a href="#" className="glyphicon glyphicon-remove-sign"
                           key={gh}
                           style={{color: 'darkred', float: 'left', paddingRight: '5px'}}
                           data-toogle="tooltip" title={"vor "+gh+" Tage(n)"} />);
                }
            }
        }
        return result;
    }




    render() {

        return <div className="backups">
            { this._renderContent() }
        </div>;
    }
}

export default Backups;
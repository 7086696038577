import axios from "axios/index";
import {errorMessage, getBackendAdress, NotificationManager} from "../../../tools";

var getLastChatID = function( mode, id, callback ){

    switch( mode ) {

        case 'offline':

            Notification.info( 'offline mode not implemented' );
            break;


        default:

            axios.get(getBackendAdress( mode ) + 'php_chat.php?type=getLastID&user=' + getUsername())

                .then(function (response) {
                    if( id !== response.data[0]['id'] && response.data[0].username !== getUsername() ) {
                        NotificationManager.info("von " + response.data[0]['user'], 'neue Nachricht');
                    }
                    callback( response.data[0] );

                }).catch(function (error) {
                errorMessage(error, 'chat');
            });
            break;
    }
};

var getUsername = function(){
    var username = '';

    document.cookie.split(";").map( (item) => {
        if( item.includes("user=") ){
            username = item.replace("user=", "").trim();
        }
    });

    return username === '' ? null : username;
};

export {getUsername};
export {getLastChatID};
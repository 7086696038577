import React from 'react';
import styled from 'styled-components';
import colors from './colors';

export const BUTTON_TYPES = {
	primary: "primary",
	secondary: "secondary",
	tertiary: "tertiary"
};

const chooseStyle = (type, forPrimary, forSecondary, forTertiary) => {
	switch (type) {

		case BUTTON_TYPES.primary:
			return forPrimary;

		case BUTTON_TYPES.secondary:
			return forSecondary;

		case BUTTON_TYPES.tertiary:
		default:
			return forTertiary;
	}
};

const ButtonOldSchool = styled.button`
	
	outline-style: none;
    margin: 0;
    padding: 0;
    margin-top: 25px;
    width: 100%;


	border-style: ${ props => chooseStyle( props.type, "none", "solid", "none")};
	border-width: 1px;
	border-radius: 3px;
	text-decoration: ${ props => chooseStyle( props.type, "none", "none", "underline")};;

    background-color: ${ props => chooseStyle( props.type, colors.button.backgroundColor.primary, colors.button.backgroundColor.secondary, colors.button.backgroundColor.tertiary)};
    color: ${colors.button.color};
    :active {
	    transform: translateY(2px);
	    filter: saturate(150%);
	},
	:focus {
		outline-style: none;
	}
`;

export default ButtonOldSchool;
import React, {Component} from 'react';
import InputFieldOldSchool from "../../____style_definitions/InputFieldOldSchool";
import ButtonOldSchool, {BUTTON_TYPES} from "../../____style_definitions/ButtonOldSchool";


class Entwicklung extends Component {

	render(){
		return <div style={{width: "500px"}}>
			<InputFieldOldSchool type={"text"} />
			<InputFieldOldSchool type={"password"} />
			<ButtonOldSchool type={BUTTON_TYPES.primary}>Ok</ButtonOldSchool>
			<ButtonOldSchool type={BUTTON_TYPES.secondary}>Ok</ButtonOldSchool>
			<ButtonOldSchool type={BUTTON_TYPES.tertiary}>Ok</ButtonOldSchool>
		</div>;
	}
}

export default Entwicklung;
import * as React from "react";

export default class userSettingsElement extends React.Component {

    render() {
        return (
                <div className="usersettings item"
                     onClick={this.props.gotoPage}
                >Benutzereinstellung</div>
        )
    }
}
import React, {Component} from 'react';

import './cpuload.css'

import axios from 'axios';
import {getBackendAdress, errorMessage} from '../../../tools';
import { Line, Circle } from 'rc-progress';

import { Chart } from 'react-google-charts';


class CPULoad extends Component {

    constructor(props){
        super(props);

        this.state = {
            cpuload: {
                currentValue: this.props.coreCount*100,
                topTasks: []
            },
            coreCount: this.props.coreCount,
            cpuHistory: [['Zeitachse','Auslastung']],
            cpuTasks: [],
            timerInterval: 5000,
            historyLength: 60
        };

        this._getCPULoad( this.props.mode );

        if( this.timerID === undefined ) {
            this.timerID = setInterval(
                () => this._getCPULoad( this.props.mode ),
                this.state.timerInterval
            );
        }
    }

    componentWillReceiveProps(nextProps) {

        this._getCPULoad( nextProps.mode );
    }

    componentDidMount(){

        this._getCPULoad( this.props.mode );
    }

    componentWillUnmount() {
        this.timerID = undefined;
        clearInterval(this.timerID);
    }

    levelOfWarning( percentage ){

        if( percentage < 60 ){
            return "lightgreen";
        }
        if( percentage < 80 ){
            return "yellow";
        }
        if( percentage < 90 ){
            return "orange";
        }
        return 'red';
    }

    _getCPULoad(){

        var $self = this;

        if( this.timerID !== undefined ) {
            switch ( this.props.mode ) {

                case 'offline':
                    axios.get('offline/cpuload.json')

                        .then(function (response) {

                            $self.setState({cpuload: response.data.cpuload});
                            $self._setNewHistoryCPUValue( $self, response.data.cpuload.currentValue );
                        }).catch(function (error) {

                        errorMessage(error, 'system');
                    });
                    break;

                default:
                    axios.get( getBackendAdress( this.props.mode ) + 'php_system.php?part=cpuload')

                        .then(function (response) {

                            $self.setState({cpuload: response.data.cpuload});
                            $self._setNewHistoryCPUValue( $self, response.data.cpuload.currentValue );
                        }).catch(function (error) {

                        errorMessage(error, 'system');
                    });
                    break;
            }
        }
    }

    _setNewHistoryCPUValue( $self, value ){

        let newHistory = $self.state.cpuHistory;

        if( newHistory.length > $self.state.historyLength ) {
            newHistory.splice( 1, 1 );
        }

        newHistory.push([
            0,
            Math.round( (100-value/$self.props.coreCount) *10 )/10
        ]);

        for(let idx=1; idx < newHistory.length; idx++){
            newHistory[idx][0] = (newHistory.length-idx)*-1*$self.state.timerInterval/1000;
        }

        $self.setState({cpuHistory: newHistory});
    }


    _renderCPULoad(){


        let percentageUsed = Math.round( (100-this.state.cpuload/this.props.coreCount) *10 )/10;

        return (
            <div>
                <Line percent={percentageUsed} strokeWidth="4"
                      strokeColor={this.levelOfWarning(percentageUsed)}/>
                {percentageUsed}
            </div>);
    }

    _renderCPUTasks(){

        let renderOutput = [
            <div className="row head" key={0}>
                <div className="col-md-2">CPU</div>
                <div className="col-md-2">MEM</div>
                <div className="col-md-8">Command</div>
            </div>
        ];

        for( let idx=0; idx < this.state.cpuload.topTasks.length; idx++ ){
            renderOutput.push(
                <div className="row data" key={idx+1}>
                    <div className="col-md-2" style={{textAlign: 'right'}}>
                        {this.state.cpuload.topTasks[idx].cpu}
                    </div>
                    <div className="col-md-2" style={{textAlign: 'right'}}>
                        {this.state.cpuload.topTasks[idx].mem}
                    </div>
                    <div className="col-md-8" style={{overflow: "scroll", borderRightWidth: "15px", borderRightStyle: "solid", borderRightColor: "transparent"}}>
                        {this.state.cpuload.topTasks[idx].cmd}
                    </div>
                </div>
            )
        }

        return renderOutput;
    }




    render() {


        let percentageUsed = Math.round( (100-this.state.cpuload.currentValue/this.props.coreCount) *10 )/10;

        return <div className="cpuload">

            <div className="row">
                <div className="col-md-3 currentValue" style={{textAlign: 'center'}}>
                    <div style={{width: '100px',position: 'absolute','textAlign': 'center','marginTop': '53px', 'marginLeft': '30px','fontSize': '28px'}}>{percentageUsed+" %"}</div>
                    <Circle percent={percentageUsed} strokeWidth="12"
                          strokeColor={this.levelOfWarning(percentageUsed)}/>
                </div>
                <div className="col-md-9 cpuTasks">
                    { this._renderCPUTasks() }
                </div>
            </div>
            <div style={{width: '100%',float: 'left', marginTop: '10px'}}>
                <Chart
                    chartType="LineChart"
                    data={ this.state.cpuHistory }
                    options={{
                        legend: 'none',
                        vAxis: {
                            title: 'Auslastung',
                            minvalue: 0,
                            maxValue: 100
                        },
                        hAxis: {
                            minvalue: -200,
                            maxValue: 0
                        }
                    }}
                    width="100%"
                    height="280px"
                    legend_toggle
                />
            </div>
        </div>;
    }
}

export default CPULoad;
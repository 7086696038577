import React from 'react';
import styled from 'styled-components';
import colors from './colors';

const InputFieldOldSchool = styled.input`
	width: 100%;
	background-color: transparent;
	border-width: 0px;
	border-bottom-width: ${colors.border.width + 'px'};
	border-bottom-style: solid;
	border-bottom-color: ${colors.border.color};
	outline-style: none;
	padding-right: 20px;
	padding-left: 20px;
	
    box-shadow: 0px 2px rgba(0,0,0,0.5);
    
    &:focus {
		border-bottom-color: ${colors.selected};
    };
`;

export default InputFieldOldSchool;
import React, {Component} from 'react';

import './sidebar.css';

import LogoutElement from "./Components/LogoutElement";
import BuildInformationElement from "./Components/BuildInformationElement";
import UserSettingsElement from "./Components/UserSettingsElement";
import WatchElement from "./Components/WatchElement";
import SidebarItem from "./Components/SidebarItem";

import links from "./Navigation";


export default class sidebar extends Component {


    state = links;


    GoToPage = (page) => {

        this.setState({page: page});
        this.props.OnPageChange(page);

        if (page === 'chat') {
            this.props.OnChangeNewMessage(false);
        }
    };

    getParent = page => page.indexOf('.') > 0 ? page.substring(0, page.indexOf('.')) : page;

    isParentSelected = (selectedPage, currentPage) =>
        this.getParent(selectedPage) === this.getParent(currentPage);

    showSidebar = () => {

        return (
            <div className="sidebar">

                <LogoutElement/>

                <UserSettingsElement gotoPage={() => this.GoToPage('usersettings')}/>

                <BuildInformationElement/>

                <WatchElement gotoPage={() => this.GoToPage('calendar')}/>

                {
                    this.state.links.map(item =>
                        <SidebarItem
                            item={item}
                            highlighting={item.page === 'chat' && this.props.hasNewMessages}
                            selected={this.props.page === item.page}
                            isParentSelected={this.isParentSelected(this.props.page, item.page)}
                            onClick={() => this.GoToPage(item.page)}
                        />
                    )
                }
            </div>);
    };

    hideSidebar = () =>
        <div className="sidbar sidebar-close"></div>;


    render() {
        if (this.props.visibleSidebar) {
            return (this.showSidebar());
        }
        return (this.hideSidebar());
    }
}
import React, {Component} from 'react';
import './countdown.css';

import Countdown from 'react-cntdwn';

class CountDown extends Component {


    handleFinish() {
        console.log('Skynet has become self-aware!');
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    finishTimer(){

    }

    convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate;
    }

    render() {

        var endDateTesto = new Date('2017-11-30T16:00:00Z');
        var endDateNovedia = new Date('2015-03-31T16:00:00Z');
        var endDateKCART = new Date('2012-09-30T16:00:00Z');
        var endDateMailReturn = new Date('2010-05-31T16:00:00Z');

        return (
            <div>
                <div className='counter-frame counter-testo'>
                    <div className='countdown'>
                        <div className='countertext'>
                            { endDateTesto.getTime() > new Date().getTime() ? "Ausscheiden" : "Erlöst" }
                        </div>
                        <div className='counter'>
                            <Countdown targetDate={endDateTesto}
                                       startDelay={2000}
                                       interval={1000}
                                       timeSeparator={':'}
                                       leadingZero
                                       format={{
                                           day: 'DD',
                                           hour: 'HH',
                                           minute: 'MM',
                                           second: 'SS'
                                       }}
                                       onFinished={this.handleFinish} />
                        </div>
                        <div className="countersubtext">{endDateTesto.toString()}</div>
                    </div>
                </div>
                <div className='counter-frame counter-novedia'>
                    <div className='countdown'>
                        <div className='countertext'>
                            { endDateNovedia.getTime() > new Date().getTime() ? "Ausscheiden" : "Erlöst" }
                        </div>
                        <div className='counter'>
                            <Countdown targetDate={endDateNovedia}
                                       startDelay={2000}
                                       interval={1000}
                                       timeSeparator={':'}
                                       leadingZero
                                       format={{
                                           day: 'DD',
                                           hour: 'HH',
                                           minute: 'MM',
                                           second: 'SS'
                                       }}
                                       onFinished={this.handleFinish} />
                        </div>
                        <div className="countersubtext">{endDateNovedia.toString()}</div>
                    </div>
                </div>
                <div className='counter-frame counter-kcart'>
                    <div className='countdown'>
                        <div className='countertext'>
                            { endDateKCART.getTime() > new Date().getTime() ? "Ausscheiden" : "Erlöst" }
                        </div>
                        <div className='counter'>
                            <Countdown targetDate={endDateKCART}
                                       startDelay={2000}
                                       interval={1000}
                                       timeSeparator={':'}
                                       leadingZero
                                       format={{
                                           day: 'DD',
                                           hour: 'HH',
                                           minute: 'MM',
                                           second: 'SS'
                                       }}
                                       onFinished={this.handleFinish} />
                        </div>
                        <div className="countersubtext">{endDateKCART.toString()}</div>
                    </div>
                </div>
                <div className='counter-frame counter-MailReturn'>
                    <div className='countdown'>
                        <div className='countertext'>
                            { endDateMailReturn.getTime() > new Date().getTime() ? "Ausscheiden" : "Erlöst" }
                        </div>
                        <div className='counter'>
                            <Countdown targetDate={endDateMailReturn}
                                       startDelay={2000}
                                       interval={1000}
                                       timeSeparator={':'}
                                       leadingZero
                                       format={{
                                           day: 'DD',
                                           hour: 'HH',
                                           minute: 'MM',
                                           second: 'SS'
                                       }}
                                       onFinished={this.handleFinish} />
                        </div>
                        <div className="countersubtext">{endDateMailReturn.toString()}</div>
                    </div>
                </div>
            </div>);
    }
}

export default CountDown;
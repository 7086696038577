import Moment from "react-moment";
import React from "react";

export default class WatchElement extends React.Component {

    constructor(props){
        super(props);
        setInterval(() => this.forceUpdate(), 1000);
    }



    render() {
        return (
            <div className="watch" style={{width: "100%", alignContent: 'center'}}
                 onClick={this.props.gotoPage}>
                <p className="time"><Moment format="HH:mm:ss">{new Date()}</Moment></p>
                <p className="date"><Moment format="DD. MMM YYYY">{new Date()}</Moment></p>
            </div>
        )
    }
}
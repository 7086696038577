export default {
	font: {
		color: "white",
		family: "UnifrakturCook"
	},
	border: {
		color: "#AAA",
		width: "1"
	},
	selected: "lightgray",
	button: {
		backgroundColor: {
			primary: "#CCC",
			secondary: "rgba(255,255,255,0.3)",
			tertiary: "transparent"
		},
		color: "#333"
	}
};
import * as React from "react";

export default class logoutElement extends React.Component {

    handleLogout = () => {
        document.cookie = 'keks=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        window.location.reload();
    };

    render(){
        return (
            <div className="logoutButton glyphicon glyphicon-off" style={{width: "100%",alignContent: 'center'}} onClick={this.handleLogout} />
        );
    }
};
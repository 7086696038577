import React, {Component} from 'react';

import offlineData_dashletConfigurations from './dashletStatistic.config.demo.json'

import axios from 'axios';
import './dashletStatistic.css';
import DashletConfiguration from './dashlet-configuration';
import ErrorDashlet from '../errorDashletPage';

import {getBackendAdress, errorMessageDashlet} from '../../../tools';


class DashletStatistic extends Component {

    constructor(props) {
        super(props);

        this.state = {
            params: this.props.params,
            data: {},
            error: false
        };

        this.getDataFromBackend( this.props.params.params.year );

        if( this.timerID === undefined ) {
            this.timerID = setInterval(
                () => this.tick(),
                10000
            );
        }
    }

    componentWillReceiveProps(nextProps) {

        this.setState( {params: nextProps});

        this.getDataFromBackend( nextProps.params.params.year );
    }


    componentDidMount() {
    }

    componentWillUnmount() {
        if( this.timerID !== undefined ) {
            clearInterval(this.timerID);
        }
    }


    showDashlet( $view ){

        switch( $view ){
            case 'main':

                if( this.state.error ){
                    return <ErrorDashlet />
                } else {
                    return this.showGraphExample();
                }

            case 'configuration':

                return <DashletConfiguration
                    fnFlip={(me) => this._flip(me)}
                    fnChangeTyp={this.props.fnChangeTyp}
                    fnChangeName={this.props.fnChangeName}
                    fnChangeSize={this.props.fnChangeSize}
                    fnChangeSQL={this.props.fnChangeSQL}
                    fnDeleteDashlet={this.props.fnDeleteDashlet}
                    fnCreatDashlet={this.props.fnCreatDashlet}
                    params={this.props.params}
                    pos={this.props.pos}
                />;

            default:
                return <p>Fehler</p>;
        }
    }

    tick(){
        this.getDataFromBackend( this.props.params.params.year );
    }


    showGraphExample(){

        return (
            <div className="statistic-table">{
                Object.keys(this.state.data).map(( $key, $value ) => {
                    return (
                        <div className={$value % 2 === 1 ? 'row row-even' : 'row'} key={$key}>
                            <div className="col-sm-8 statistic-key">{$key}</div>
                            <div className="col-sm-4 statistic-value">{this.state.data[$key]}</div>
                        </div>);
                })
            }</div>);
    }

    getDataFromBackend( $year ){

        var $self = this;

        switch( this.props.mode ) {

            case 'offline':
                $self.setState({data: offlineData_dashletConfigurations});
                break;

            default:

                axios.get( getBackendAdress( this.props.mode ) + 'php_bank.php?question=getStatistic&year=' + $year)

                    .then(function (response) {

                        $self.setState({data: response.data});
                        $self.setState({error: false});

                    }).catch(function (error) {

                    errorMessageDashlet(error, $self.props.params.params);
                    $self.setState({error: true});
                });
                break;
        }
    }


    render() {

        return this.showDashlet( this.props.params.side );
    }
}

export default DashletStatistic;
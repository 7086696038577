import React, {Component} from 'react';

class GoodOrBad extends Component {

    showResult( value ){

        switch( value ){
            case "2":
                return <div className="glyphicon glyphicon-thumbs-up"
                            style={{color: 'orangecd', 'fontSize': 20, 'fontWeight': 'bold'}}>
                </div>;
            case "3":
                return <div className="glyphicon glyphicon-thumbs-down"
                            style={{color: 'violet', 'fontSize': 20, 'fontWeight': 'bold'}}>
                </div>;
            default:
                return <div></div>;
        }
    }

    render(){
        return ( this.showResult( this.props.value ));
    }
}

export default GoodOrBad;
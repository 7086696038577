import React, {Component} from 'react';

import './bookmarks.css';
import axios from "axios/index";
import {errorMessage, getBackendAdress} from "../../../tools";

class Bookmarks extends Component {


    constructor(props) {
        super(props);

        this.state = {
            bookmarks: []
        };

        this._getBookmarks();
    }


    componentWillReceiveProps(nextProps) {

        this._getBookmarks( nextProps.mode );
    }

    _getBookmarks(){

        var $self = this;

        switch( this.props.mode ) {

            case 'offline':
                break;

            default:

                axios.get( getBackendAdress( this.props.mode ) + 'php_bookmarks.php')

                    .then(function (response) {
                        $self.setState({bookmarks: response.data});
                    }).catch(function (error) {
                    errorMessage(error, 'bookmarks');
                });
                break;
        }
    }

    renderBookmarkItems(){

        let items = [];

        this.state.bookmarks.map( (item) => {
            items.push( this.renderBookmarkItem( item ) );
        });

        return items;
    }


    renderBookmarkItem( item ){
        return (<div className="bookmarkItem" key={item.name}>
            <a href={item.url} target="_blank">
                { item.logo ? <img src={item.logo} /> : item.name }
            </a>
            <p>{item.name}</p>
        </div>);
    }


    render(){
        return ( <div className="bookmarks">
            { this.renderBookmarkItems() }
            </div>);
    }
}

export default Bookmarks;
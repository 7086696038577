import React, {Component} from 'react';
import './calendar.css';

import moment from 'moment';
import dataBerlin from './data_berlin.json';

import Month from './monthCalendar';

class calendar extends Component {


    constructor(props) {
        super(props);

        this.state = {
            year: new Date().getFullYear(),
            data: dataBerlin,
            dataBerlin: dataBerlin,

            options: {
                showHolidays: this.props.options !== null && this.props.options.showHolidays === null ? true : this.props.options.showHolidays
            }
        };
    }

    componentDidMount() {
        this.addEasterHolidays( this.state.year );
    }

    addEasterHolidays( year ){

        let m = 24;
        let n = 5;

        let a = year % 4;
        let b = year % 7;
        let c = year % 19;
        let d = ( 19 * 3 + m ) % 30;
        let e = ( 2 * a + 4 * b + 6 * d + n) % 7;
        let f = parseInt( ( c + 11 * d + 22 * e ) / 451 );

        let easterDays = 22 + d + e - 7 * f;

        var easterDate = moment( year + "-03-01", 'YYYY-MM-DD', true).add( easterDays+6, 'day');

        var dataData = [];
        this.state.dataBerlin.map( (item) => {
            dataData.push( item );
            return 0;
        });

        let karfreitag = easterDate.clone().subtract( 2, 'day' );
        dataData.push({
            month: parseInt( karfreitag.format('MM') ),
            day: parseInt( karfreitag.format('DD') ),
            description: 'Karfreitag'
        });

        dataData.push({
            month: parseInt( easterDate.format('MM') ),
            day: parseInt( easterDate.format('DD') ),
            description: 'Ostersonntag'
        });

        let osterMontag = easterDate.clone().add( 1, 'day' );
        dataData.push({
            month: parseInt( osterMontag.format('MM') ),
            day: parseInt( osterMontag.format('DD') ),
            description: 'Ostermontag'
        });

        let pfingsten = easterDate.clone().add( 49, 'day');
        dataData.push({
            month: parseInt( pfingsten.format('MM') ),
            day: parseInt( pfingsten.format('DD') ),
            description: 'Pfingsten'
        });

        let pfingstMontag = easterDate.clone().add( 50, 'day');
        dataData.push({
            month: parseInt( pfingstMontag.format('MM') ),
            day: parseInt( pfingstMontag.format('DD') ),
            description: 'Pfingstmontag'
        });

        let himmelfahrt = easterDate.clone().add( 39, 'day');
        dataData.push({
            month: parseInt( himmelfahrt.format('MM') ),
            day: parseInt( himmelfahrt.format('DD') ),
            description: 'Himmelfahrt'
        });

        this.setState({data: dataData});
    }

    navigationbar(){
        return (
            <div className="cal-header row">

                <div className="col-md-4">
                    <div className="cal-navigation" onClick={() => {
                        this.setState({year: this.state.year-1});
                        this.addEasterHolidays( this.state.year-1 )
                    }}>zurück</div>
                </div>

                <div className="col-md-4 cal-year">{this.state.year}</div>

                <div className="col-md-4">
                    <div className="cal-navigation" onClick={() => {
                        this.setState({year: this.state.year+1});
                        this.addEasterHolidays( this.state.year+1 )
                    }}>weiter</div>
                </div>

            </div>);
    }

    render() {
        return <div className="calendar">
            { this.navigationbar() }
            <div className="body">
                <div className="row" >
                    <div className="col-md-3 cal-month"><Month year={this.state.year} month={1} holidays={this.state.data} options={this.props.options} /></div>
                    <div className="col-md-3 cal-month"><Month year={this.state.year} month={2} holidays={this.state.data} options={this.props.options} /></div>
                    <div className="col-md-3 cal-month"><Month year={this.state.year} month={3} holidays={this.state.data} options={this.props.options} /></div>
                    <div className="col-md-3 cal-month"><Month year={this.state.year} month={4} holidays={this.state.data} options={this.props.options} /></div>
                </div>
                <div className="row" >
                    <div className="col-md-3 cal-month"><Month year={this.state.year} month={5} holidays={this.state.data} options={this.props.options} /></div>
                    <div className="col-md-3 cal-month"><Month year={this.state.year} month={6} holidays={this.state.data} options={this.props.options} /></div>
                    <div className="col-md-3 cal-month"><Month year={this.state.year} month={7} holidays={this.state.data} options={this.props.options} /></div>
                    <div className="col-md-3 cal-month"><Month year={this.state.year} month={8} holidays={this.state.data} options={this.props.options} /></div>
                </div>
                <div className="row" >
                    <div className="col-md-3 cal-month"><Month year={this.state.year} month={9} holidays={this.state.data} options={this.props.options} /></div>
                    <div className="col-md-3 cal-month"><Month year={this.state.year} month={10} holidays={this.state.data} options={this.props.options} /></div>
                    <div className="col-md-3 cal-month"><Month year={this.state.year} month={11} holidays={this.state.data} options={this.props.options} /></div>
                    <div className="col-md-3 cal-month"><Month year={this.state.year} month={12} holidays={this.state.data} options={this.props.options} /></div>
                </div>
            </div>
        </div>;
    }
}

export default calendar;
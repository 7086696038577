import {NotificationManager} from 'react-notifications';

var errorMessage = function(message, title){

    NotificationManager.error( message.toString(), title);
};

var errorMessageDashlet = function(message, properties){

    NotificationManager.error(
        message.toString(),
        properties.dashletType + '/' + properties.name );
};

var getBackendAdress = function( mode ){

    switch( mode ){

        case 'prod':
            // same url
            return "";

        case 'dev':
            // test enviroment
        default:
            return "https://www.super-dupa-pinky.de/testPrivate/";
    }
};

export {NotificationManager};
export {errorMessage};
export {errorMessageDashlet};
export {getBackendAdress}
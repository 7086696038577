import React, {Component} from 'react';

import './chat.css';
import axios from "axios/index";
import {errorMessage, getBackendAdress} from "../../../tools";


class Chat extends Component {

    constructor(props) {
        super(props);

        this.state = {
            chats: [],
            lastID: this.props.lastChatID
        };

        this._getChatMessages( props.mode );
    }

    componentWillReceiveProps(nextProps) {
        this._getChatMessages( nextProps.mode );

        if( document.getElementsByClassName("chat_messages") ) {
            document.getElementsByClassName("chat_messages")[0].scrollTop = 10000000;
        }
    }

    _getChatMessages(){

        var $self = this;

        switch( this.props.mode ) {

            case 'offline':
                Notification.info( 'offline mode not implemented' );
                break;

            default:

                axios.get( getBackendAdress( this.props.mode ) + 'php_chat.php?type=getMessages')

                    .then(function (response) {
                        if( response.data.length > 0 ) {
                            $self.setState({chats: response.data});
                            $self.setState({lastID: response.data[response.data.length - 1].id});
                            document.getElementsByClassName("chat_message_input")[0].value = "";
                        }
                    }).catch(function (error) {
                        errorMessage(error, 'chat');
                });
                break;
        }
    }

    getUser(){

        let username = "";

        if( document.cookie.includes(";") ) {
            document.cookie.split(";").map((item) => {
                if (item.includes("user=")) {
                    username = item.replace("user=", "");
                }
            });
        }

        return username;
    }

    sendMessage(){

        let $self = this;
        let user = this.getUser();
        let message = document.getElementsByClassName("chat_message_input")[0].value;

        axios.get(getBackendAdress( this.props.mode ) + 'php_chat.php?type=sendMessage&user='+user+'&message='+encodeURIComponent(message))
            .then(function(response){

            $self._getChatMessages( $self.props.mode );
        }).catch(function(error){

            errorMessage(error, 'chat');
        });
    }


    rendeChatItems(){

        let items = [];
        let lastNickName = '';

        if( this.state.chats && this.state.chats.length > 0) {
            this.state.chats.map((item) => {
                items.push(this.renderChatItem(item, item.user === lastNickName ));
                lastNickName = item.user;
            });
        }
        return items;
    }
    renderChatItem( item, sameUser ){
        let lineColor = this.string2number( item.user )%150 + 100;
        return (<div className="row chat_message" key={item.timestamp} style={{backgroundColor: 'rgba('+lineColor+','+lineColor+','+lineColor+',.3)'}} >
            <div className="col-md-2">
                <div className="chat_message_user">{ sameUser ? '' : item.user }</div><div className="chat_message_timestamp">{ item.timestamp }</div>
            </div>
            <div className="col-md-10 chat_message_chat">{ this.renderMessage( item.message ) }</div>
        </div>);
    }

    string2number( text ){
        let number = 0;
        text.split('').map( (char) => {
            number += char.charCodeAt(0);
        });
        return number;
    }

    renderMessage( message ){

        return this.makeLinksVisible( message );
    }
    makeLinksVisible( message ){
        let newMessageArray = [];
        let newMessage = '';
        message.split(' ').map( (word, i) => {
            if( word.startsWith('https://') || word.startsWith('http://') || word.startsWith('www.')){
                newMessageArray.push( <font key={Math.random()}>{ newMessage }</font> );
                newMessageArray.push( <a key={Math.random()} href={word} target="_blank">{word}</a> );
                newMessage = ' ';
            } else {
                newMessage += word+' ';
            }
        });
        newMessageArray.push( <font key={Math.random()}>{ newMessage }</font> );

        return newMessageArray;
    }



    render(){
        return ( <div className="chats">
            <div className="chat_messages">{ this.rendeChatItems() }</div>
            <div className="chat_input">
                <div className="row">
                    <div className="col-md-9">
                        <textarea rows="2" className="chat_message_input"/>
                    </div>
                    <div className="col-md-2">
                        <button className="chat_message_send btn btn-default" onClick={this.sendMessage.bind(this)}>Senden</button>
                    </div>
                </div>
            </div>

        </div>);
    }
}

export default Chat;
/**
 * Created by dag on 24.07.17.
 */
import React, {Component} from 'react';
import {Button} from 'react-bootstrap/dist/react-bootstrap';
import 'react-notifications/lib/notifications.css';

import { Chart } from 'react-google-charts';
import animationSpinner from './../widgets/spinner.gif';

import axios from 'axios';
import {getBackendAdress,errorMessage} from '../../../tools';

import './pension.css';
import Moment from "react-moment/dist/index";


var moment = require('moment');
Moment.globalLocale = 'de';
Moment.globalFormat = 'DD MMM YYYY';


class Pension extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            diagrammData: {
                OnExit: [],
                Score: []
            }
        };

        this.getData();
    }

    componentWillReceiveProps(nextProps) {

        this.getData();
    }

    getData(){

        switch( this.props.mode ){

            case 'offline':
                break;

            default:
                var $self = this;
                axios.get( getBackendAdress( this.props.mode ) + 'php_pension.php?type=getTable')

                    .then(function (response) {

                        $self.setState({tableData: response.data });
                        $self.prepairDiagrammData( response.data.slice(0).reverse() );

                    }).catch(function (error) {
                    errorMessage(error, 'Rente');
                });
                break;
        }
    }
    prepairDiagrammData( $data ){

        var diagrammData_onExit = [];
        diagrammData_onExit.push( ['Jahre', 'jetzt', 'bei Betriebsunfähigkeit', 'mit 65 Jahren'] );
        $data.map( (item) => {
            diagrammData_onExit.push( [
                item.validateDate.substring( 0, 4 ),
                parseFloat( item.exitOnNow ),
                parseFloat( item.exitOnSickness ),
                parseFloat( item.exitOnEnd )
            ] );
        });


        var diagrammData_Score = [];
        diagrammData_Score.push( ['Jahre', 'Punkte'] );
        $data.map( (item) => {
            diagrammData_Score.push( [ item.validateDate.substring( 0, 4 ), parseFloat( item.score ) ] );
        });

        this.setState({diagrammData: {
            OnExit: diagrammData_onExit,
            Score: diagrammData_Score
        }});
    }

    renderTable(){
        return (
            <div className="table">
                { this.renderTableHead() }
                <div className="rowline" />
                { this.renderTableContent() }
            </div>);
    }
    renderTableHead(){
        return (
            <div className="header">
                <div className="pensionHead">Prognose - Rückblick:</div>
                <div className="row">
                    <div className="col-md-2 floatCenter colEven">Stand</div>
                    <div className="col-md-5 floatCenter colNotEven">Rente</div>
                    <div className="col-md-5 floatCenter colEven">Einzahlungen</div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        <div className="row">
                            <div className="col-md-12 floatCenter colEven">(Allgemein)</div>
                        </div>
                    </div>
                    <div className="col-md-5 colNotEven">
                        <div className="row">
                            <div className="col-md-4 floatCenter">mit 65</div>
                            <div className="col-md-4 floatCenter">bei Arbeitsunfähigkeit</div>
                            <div className="col-md-4 floatCenter">sofort</div>
                        </div>
                    </div>
                    <div className="col-md-5 colEven">
                        <div className="row">
                            <div className="col-md-4 floatCenter">von mir</div>
                            <div className="col-md-4 floatCenter">von Arbeitgeber</div>
                            <div className="col-md-4 floatCenter">von Kasse</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    renderTableContent(){
        return (
            <div className="tableContent">
                { this.prepairTableContent() }
            </div>
        );
    }
    prepairTableContent(){

        let renderObject = [];
        this.state.tableData.map( (item) => {

            renderObject.push(
                <div className="row" key={renderObject.length}>

                    <div className="col-md-1"><Moment format="DD. MMMM YYYY">{ item.validateDate }</Moment></div>
                    <div className="col-md-1 floatRight colEven">{ item.score.replace('.',',') } Punkte</div>
                    <div className="col-md-5">
                        <div className="row">
                            <div className="col-md-4 floatRight colNotEven">{ this.numberWithPoint( item.exitOnEnd ) } €</div>
                            <div className="col-md-4 floatRight colEven">{ this.numberWithPoint( item.exitOnSickness ) } €</div>
                            <div className="col-md-4 floatRight colNotEven">{ this.numberWithPoint( item.exitOnNow ) } €</div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="row">
                            <div className="col-md-4 floatRight colEven">{ this.numberWithPoint( item.investMe ) } €</div>
                            <div className="col-md-4 floatRight colNotEven">{ this.numberWithPoint( item.investEmployer ) } €</div>
                            <div className="col-md-4 floatRight colEven">{ this.numberWithPoint( item.investPublic ) } €</div>
                        </div>
                    </div>
                </div>
            );
        });
        return renderObject;
    }

    numberWithPoint( value ){
        value = value.toString().replace(".", ",");
        value =value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return value;
    }

    renderGraph( title, data ){
        return (
            <div>
                <div className="pensionHead">{title}</div>
                <Chart
                    chartType="LineChart"
                    data={ data }
                    options={{
                        hAxis: {
                            minValue: 0
                        }
                    }}
                    loader={<div className="dashlet-spinner" style={{marginTop: '90px', width: '100%', textAlign: 'center', backgroundColor: 'rbga(200,200,200.6)'}}><img src={animationSpinner} /></div>}
                    width="100%"
                    height="280px"
                    legend_toggle
                />
            </div>);
    }

    render(){
        return (
            <div className="pension">
                <div className="hinweis">In dieser Übersicht ist die Inflation nicht mit eingerechnet. Die Einträge wurden von der Rentenkasse prognostiziert. Die letzten 5 Jahre gelten dabei als Berechnungsgrundlage und angenommen, dass sich diese auch in Zukunft (Gehalt) nicht ändert.</div>
                { this.renderGraph( 'Gesammelte Rentenpunkte', this.state.diagrammData.Score ) }
                { this.renderGraph( 'Ausstieg dieses Jahr', this.state.diagrammData.OnExit ) }
                { this.renderTable() }
            </div>
        );
    }
}


export default Pension;
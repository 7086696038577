import React, {Component} from 'react';
import './harddiskdrives.css';

import { Line } from 'rc-progress';


class harddiskdrives extends Component {

    levelOfWarning( percentage ){

        if( percentage < 60 ){
            return "lightgreen";
        }
        if( percentage < 80 ){
            return "yellow";
        }
        if( percentage < 90 ){
            return "orange";
        }
        return 'red';
    }


    showHardDisDrives(){
        let drives = [];

        if( this.props.data ) {

            let harddiskdrives = this.props.data;

            for (let idx = 0; idx < harddiskdrives.length; idx++) {

                if( harddiskdrives[idx].FileSystem.length > 0 ) {

                    let percentageUsed = harddiskdrives[idx].Capacity.replace('%', '');
                    drives.push(
                        <div className="row drive data" key={idx}>
                            <div className="col-md-3">{harddiskdrives[idx].FileSystem}</div>
                            <div className="col-md-2">{harddiskdrives[idx].Size}</div>
                            <div className="col-md-2">{harddiskdrives[idx].Avail}</div>
                            <div className="col-md-2">
                                <Line percent={percentageUsed} strokeWidth="4"
                                      strokeColor={this.levelOfWarning(percentageUsed)}/>
                                {harddiskdrives[idx].Capacity}
                            </div>
                            <div className="col-md-3">{harddiskdrives[idx].Mounted}</div>
                        </div>
                    );
                }
            }
        }

        return <div>{drives}</div>;
    }

    render() {
        return <div className="harddiskdrives">
            <div className="row drive head">
                <div className="col-md-3">Gerät</div>
                <div className="col-md-2">Maximale Größe</div>
                <div className="col-md-2">Freier Speicher</div>
                <div className="col-md-2">Auslastung</div>
                <div className="col-md-3">Mount Punkt</div>
            </div>
            {this.showHardDisDrives()}
        </div>;
    }
}

export default harddiskdrives;
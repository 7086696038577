import React, {Component} from 'react';

import './dashlet.css';

import { Flipper, Front, Back } from 'react-flipper';

import DashletContent from './dashlet-content';

class Dashlet extends Component {


    constructor(props) {
        super(props);

        this.state = {
            isFlipped: this.props.dashletType ? false : true,
            params: this.props
        };

        this._flip = this._flip.bind (this);
    }

    _flip () {
        this.setState ({ isFlipped: !this.state.isFlipped });
    }

    _renderFlipButton(){
        return (
            <div className="button-flipper-config">
                <div onClick={this._flip} />
            </div>
        );
    }


    render() {

        return (
            <div className={this.props.size > 1 ? ' dashlet dashlet-double-size' : 'dashlet'}>
                <Flipper isFlipped={this.state.isFlipped} orientation="horizontal">

                    <Front className="dashlet-front dashlet-item" isFront={this.props.isFlipped}>
                        { this._renderFlipButton() }
                        <DashletContent
                            mode={this.props.mode}
                            isFront={!this.state.isFlipped}
                            fnFlip={(me) => this._flip(me)}
                            fnChangeTyp={this.props.fnChangeTyp}
                            fnChangeName={this.props.fnChangeName}
                            fnChangeSize={this.props.fnChangeSize}
                            fnChangeSQL={this.props.fnChangeSQL}
                            fnDeleteDashlet={this.props.fnDeleteDashlet}
                            fnCreatDashlet={this.props.fnCreatDashlet}
                            params={this.props}
                            pos={this.props.pos}
                            side="main"/>

                    </Front>

                    <Back className="dashlet-back dashlet-item" isFront={this.props.isFlipped}>
                        { this._renderFlipButton() }
                        <DashletContent
                            mode={this.props.mode}
                            isFront={!this.state.isFlipped}
                            fnFlip={(me) => this._flip(me)}
                            fnChangeTyp={this.props.fnChangeTyp}
                            fnChangeName={this.props.fnChangeName}
                            fnChangeSize={this.props.fnChangeSize}
                            fnChangeSQL={this.props.fnChangeSQL}
                            fnDeleteDashlet={this.props.fnDeleteDashlet}
                            fnCreatDashlet={this.props.fnCreatDashlet}
                            params={this.props}
                            pos={this.props.pos}
                            side="configuration"/>

                    </Back>
                </Flipper>
            </div>
        )
    }
}

export default Dashlet;
/**
 * Created by dag on 24.07.17.
 */
import Toggle from 'react-bootstrap-toggle';
import {Tabs, Tab} from 'react-bootstrap';
import './../../../../node_modules/react-bootstrap-toggle/dist/bootstrap2-toggle.css';

import GoodOrBad from './../../../__ext_tools/GoodOrBad';
import {getBackendAdress} from "../../../tools";

import React, {Component} from 'react';
import Modal from 'react-modal';
import {Button, FormControl} from 'react-bootstrap/dist/react-bootstrap';

//import Select2 from 'react-select2-wrapper';
//import 'react-select2-wrapper/css/select2.css';
import Select from 'react-select';

import 'react-notifications/lib/notifications.css';

import ReactStars from 'react-stars';
import './dialogDetails.css';

import Moment from 'react-moment';
var moment = require('moment');
Moment.globalLocale = 'de';
Moment.globalFormat = 'DD MMM YYYY HH:mm';


const customStyles = {
    overlay : {
        position          : 'fixed',
        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
        backgroundColor   : 'rgba(255, 255, 255, 0.2)',
    },
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        borderRadius      : '15px',
    }};


class dialogDetails extends Component {

    constructor(props) {
        super(props);

        this.onToggleGetSend = this.onToggleGetSend.bind(this);
        this.onToggleGetAnswer = this.onToggleGetAnswer.bind(this);

        this.state = {
            title: '',
            page: '',
            modalIsOpen: true,
            modus: this.props.content ? 'view' : 'new',
            content: this.props.content ? this.props.content : {stars: 0},
            tabIndex: 0
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);


        this.onChange_stars = this.onChange_stars.bind(this);
        this.onChange_comment = this.onChange_comment.bind(this);
        this.onChange_firmen_name = this.onChange_firmen_name.bind(this);
        this.onChange_firmen_url = this.onChange_firmen_url.bind(this);
        this.onChange_firmen_logo_url = this.onChange_firmen_logo_url.bind(this);
        this.onChange_job = this.onChange_job.bind(this);
        this.onChange_job_location = this.onChange_job_location.bind(this);
        this.onChange_job_url = this.onChange_job_url.bind(this);
        this.onChange_job_description = this.onChange_job_description.bind(this);
        this.onChange_candidaturs = this.onChange_candidaturs.bind(this);
        this.onChange_candidaturs_check = this.onChange_candidaturs_check.bind(this);
        this.onChange_candidaturs_send = this.onChange_candidaturs_send.bind(this);
        this.onChange_candidaturs_answer = this.onChange_candidaturs_answer.bind(this);
        this.onChange_candidaturs_regret = this.onChange_candidaturs_regret.bind(this);
        this.onChange_candidaturs_closed = this.onChange_candidaturs_closed.bind(this);
    }


    componentWillReceiveProps(nextProps) {
        console.log( 'tada' );
        this.setState({
            modalIsOpen: true,
            content: nextProps.content
        });
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    }

    closeModal() {
        // this.props.OnPageChange('finance');
        this.setState({modalIsOpen: false});
        this.props.onClose();
    }

    showEditableValue( id, value, lineCount ){

        return <textarea
            type="text"
            className="form-control"
            id={id}
            value={value}
            rows={lineCount ? lineCount : 1}></textarea>;
    }
    you_want_not_to_know(event){
        this.setState({null: event.target.value});
    }

    addURLIfNotEditMode( id, message, url ){
        if( this.state.edit_mode ){
            return this.showEditableValue( id, message );
        } else {
            return <p>{message} (<a target="_blank" href={url}>klick</a>)</p>;
        }
    }

    showIfTrue( $enable, $object){
        if( $enable ){
            return $object;
        }
    }

    showCandidaturDetails_New(){
        return (
            <div>
                <div className="header">Stellenausschreibung
                    <ReactStars
                        count={5}
                        size={24}
                        onChange={this.onChange_stars}
                        value={ parseInt( this.state.content.stars ) }
                        color2={'#ffd700'} />
                </div>
                <div className="descriptionCandidatur">
                    <div className="row modalDialogElement job_firma">
                        <div className="col-md-2">Firma:</div>
                        <div className="col-md-10">
                            <textarea
                                type="text"
                                className="form-control"
                                id='job_firma'
                                value={this.state.content.firmen_name}
                                onChange={this.onChange_firmen_name}
                                rows={1}></textarea>
                        </div>
                    </div>
                    <div className="row modalDialogElement firmen_url">
                        <div className="col-md-2">Firmenwebseite:</div>
                        <div className="col-md-10">
                            <textarea
                                type="text"
                                className="form-control"
                                id='firmen_url'
                                value={this.state.content.firmen_url}
                                onChange={this.onChange_firmen_url}
                                rows={1}></textarea>
                        </div>
                    </div>
                    <div className="row modalDialogElement firmen_logo_url">
                        <div className="col-md-2">Firmenlogo URL:</div>
                        <div className="col-md-10">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id='firmen_url'
                                        value={this.state.content.firmen_logo_url}
                                        onChange={this.onChange_firmen_logo_url}
                                        rows={1}></textarea>
                        </div>
                    </div>
                    <div className="row modalDialogElement job_location">
                        <div className="col-md-2">Ort:</div>
                        <div className="col-md-10">
                            <textarea
                                type="text"
                                className="form-control"
                                id='job_location'
                                value={this.state.content.job_location}
                                onChange={this.onChange_job_location}
                                rows={1}></textarea>
                        </div>
                    </div>
                    <div className="row modalDialogElement job">
                        <div className="col-md-2">Täigkeit:</div>
                        <div className="col-md-10">
                            <textarea
                                type="text"
                                className="form-control"
                                id='job'
                                value={this.state.content.job}
                                onChange={this.onChange_job}
                                rows={1}></textarea>
                        </div>
                    </div>
                    <div className="row modalDialogElement job_url">
                        <div className="col-md-2">Webseite Stellenausschreibung:</div>
                        <div className="col-md-10">
                            <textarea
                                type="text"
                                className="form-control"
                                id='job_url'
                                value={this.state.content.job_url}
                                onChange={this.onChange_job_url}
                                rows={1}></textarea>
                        </div>
                    </div>
                    <div className="row modalDialogElement job_description">
                        <div className="col-md-2 key">Jobbeschreibung:</div>
                        <div className="col-md-10 value">
                            <textarea
                                type="text"
                                className="form-control"
                                id='job_description'
                                value={this.state.content.job_description}
                                onChange={this.onChange_job_description}
                                rows={10}></textarea>
                        </div>
                    </div>
                    <div className="row modalDialogElement ownComment">
                        <div className="col-md-2">eigene Kommentare:</div>
                        <div className="col-md-10">
                            <textarea
                                type="text"
                                className="form-control"
                                id="ownCommentBox"
                                onChange={this.onChange_comment}
                                value={this.state.content.kommentar}
                                rows="5" />
                        </div>
                    </div>
                </div>
                <div className="dialogaction">
                    <Button
                        bsStyle="default"
                        onClick={(e) => {
                            this.closeModal(e)
                        }}>Verwerfen</Button>
                    <Button
                        bsStyle="primary"
                        onClick={(e) => {

                            this.onClickSwitchModus( 'closeANDsave' );
                        }}
                    >Übernehmen</Button>
                </div>
            </div>
        );
    }
    showCandidaturDetails_View(){

        return (
            <div style={{width:"800px"}}>
                <div className="firmen_logo">
                    <img src={this.state.content.firmen_logo_url} style={{width: "400px"}} />
                </div>
                <div className="header">Stellenausschreibung
                    <ReactStars
                        count={5}
                        size={24}
                        value={ parseInt( this.state.content.stars ) }
                        onChange={ this.onChange_stars }
                        color2={'#ffd700'} />
                </div>

                <div className="descriptionCandidatur">
                    <Tabs activeKey={this.state.tabIndex} onSelect={key => this.setState({tabIndex: key})}>
                        <Tab title='Allgemeine Informationen' eventKey={0}>
                            <div className="row modalDialogElement created">
                                <div className="col-md-2">erstellt am:</div>
                                <div className="col-md-10"><Moment>{this.state.content.created}</Moment></div>
                            </div>
                            <div className="row modalDialogElement firmen_name">
                                <div className="col-md-2">Firma:</div>
                                <div className="col-md-10">
                                    { this.addURLIfNotEditMode( 'firmen_name', this.state.content.firmen_name, this.state.content.firmen_url) }
                                    { this.state.content.kununu ? <a href={this.state.content.kununu} target='_blank'>
                                        <img style={{height: '15px'}} src='http://crosswater-job-guide.com/wp/wp-content/uploads/2010/06/logo_kununu_600.jpg' /></a> : '' }
                                </div>
                            </div>
                            <div className="row modalDialogElement job_location">
                                <div className="col-md-2">Ort:</div>
                                <div className="col-md-10">{ this.state.content.job_location }</div>
                            </div>
                            <div className="row modalDialogElement job">
                                <div className="col-md-2">Täigkeit:</div>
                                <div className="col-md-10">
                                    { this.addURLIfNotEditMode( 'job', this.state.content.job, this.state.content.job_url) }
                                </div>
                            </div>
                            <div className="row modalDialogElement candidatur_ref">
                                <div className="col-md-2">Anschreiben:</div>
                                <div className="col-md-10">{ this.showCandidaturBrief() }</div>
                            </div>
                            <div className="row modalDialogElement job_description">
                                <div className="col-md-2 key">Jobbeschreibung:</div>
                                <div className="col-md-10 value">{ this.state.content.job_description }</div>
                            </div>
                        </Tab>
                        <Tab title='mehr Informationen' eventKey={1}>
                            <div className="row modalDialogElement candidatur_send">
                                <div className="col-md-2">versendet am:</div>
                                <div className="col-md-10">
                                    {
                                        function() {
                                            if (this.props.content.candidaturs_send) {
                                                return <Moment>{this.state.content.candidaturs_send}</Moment>
                                            }
                                        }
                                    }
                                    </div>
                            </div>
                            <div className="row modalDialogElement candidatur_check">
                                <div className="col-md-2">kontrolliert:</div>
                                <div className="col-md-10">
                                    <GoodOrBad value={this.state.content.candidaturs_check}/>
                                </div>
                            </div>
                            <div className="row modalDialogElement candidatur_answer">
                                <div className="col-md-2">Antwort:</div>
                                <div className="col-md-10">{this.state.content.candidaturs_answer}</div>
                            </div>
                            <div className="row modalDialogElement candidatur_answer">
                                <div className="col-md-2">Abgelehnt:</div>
                                <div className="col-md-10">{this.state.content.candidaturs_regret ? 'ja' : 'nein' }</div>
                            </div>
                            <div className="row modalDialogElement ownComment">
                                <div className="col-md-2">eigene Kommentare:</div>
                                <div className="col-md-10">
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            id="ownCommentBox"
                                            rows="20"
                                            onChange={this.onChange_comment}
                                            value={this.state.content.kommentar}
                                        />
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
                <div className="dialogaction">
                    <Button
                        bsStyle="default"
                        onClick={(e) => {
                            this.onClickSwitchModus( 'edit' );
                        }}
                    >Editieren</Button>
                    <Button
                        bsStyle="primary"
                        onClick={(e) => {
                            this.onClickSwitchModus( 'close' );
                        }}
                    >Ok</Button>
                </div>
            </div>
        );
    }
    showCandidaturDetails_Edit(){
        return (
            <div>
                <div className="header">Stellenausschreibung
                    <ReactStars
                        count={5}
                        size={24}
                        value={ parseInt( this.state.content.stars ) }
                        onChange={this.onChange_stars}
                        color2={'#ffd700'} />
                </div>
                <div className="descriptionCandidatur">
                    <Tabs activeKey={1} onSelect={key => this.setState({tabIndex: key})}>
                        <Tab title='Allgemeine Informationen' eventKey={0}>
                            <div className="row modalDialogElement job_firma">
                                <div className="col-md-2">Firma:</div>
                                <div className="col-md-10">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id='job_firma'
                                        value={ this.state.content.firmen_name ? this.state.content.firmen_name : '' }
                                        onChange={ this.onChange_firmen_name }
                                        rows={1}></textarea>
                                </div>
                            </div>
                            <div className="row modalDialogElement firmen_url">
                                <div className="col-md-2">Firmenwebseite:</div>
                                <div className="col-md-10">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id='firmen_url'
                                        value={ this.state.content.firmen_url ? this.state.content.firmen_url : '' }
                                        onChange={this.onChange_firmen_url}
                                        rows={1}></textarea>
                                </div>
                            </div>
                            <div className="row modalDialogElement firmen_logo_url">
                                <div className="col-md-2">Firmenlogo URL:</div>
                                <div className="col-md-10">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id='firmen_url'
                                        value={ this.state.content.firmen_logo_url ? this.state.content.firmen_logo_url : ''}
                                        onChange={this.onChange_firmen_logo_url}
                                        rows={1}></textarea>
                                </div>
                            </div>
                            <div className="row modalDialogElement job_location">
                                <div className="col-md-2">Ort:</div>
                                <div className="col-md-10">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id='job_location'
                                        value={ this.state.content.job_location ? this.state.content.job_location : ''}
                                        onChange={this.onChange_job_location}
                                        rows={1}></textarea>
                                    </div>
                            </div>
                            <div className="row modalDialogElement job">
                                <div className="col-md-2">Täigkeit:</div>
                                <div className="col-md-10">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id='job'
                                        value={ this.state.content.job ? this.state.content.job : '' }
                                        onChange={this.onChange_job}
                                        rows={1}></textarea>
                                </div>
                            </div>
                            <div className="row modalDialogElement job_url">
                                <div className="col-md-2">Webseite Stellenausschreibung:</div>
                                <div className="col-md-10">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id='job_url'
                                        value={ this.state.content.job_url ? this.state.content.job_url : '' }
                                        onChange={this.onChange_job_url}
                                        rows={1}></textarea>
                                </div>
                            </div>

                            <div className="row modalDialogElement candidatur_ref">
                                <div className="col-md-2">Anschreiben:</div>
                                <div className="col-md-10">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id='job_url'
                                        value={ this.state.content.candidaturs ? this.state.content.candidaturs : '' }
                                        onChange={this.onChange_candidaturs}
                                        rows={1}></textarea>
                                </div>
                            </div>
                            <div className="row modalDialogElement job_description">
                                <div className="col-md-2 key">Jobbeschreibung:</div>
                                <div className="col-md-10 value">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id='job_description'
                                        value={ this.state.content.job_description ? this.state.content.job_description : '' }
                                        onChange={this.onChange_job_description}
                                        rows={12}></textarea>
                                    </div>
                            </div>
                        </Tab>
                        <Tab title='mehr Informationen' eventKey={1}>
                            <div className="row modalDialogElement candidatur_check">
                                <div className="col-md-2">kontrolliert:</div>
                                <div className="col-md-10">
                                    <Select
                                        value={ this.state.content.candidaturs_check }
                                        options={[
                                            { text: 'noch nicht kontrolliert',  id: 1 },
                                            { text: 'kontrolliert.. ok',        id: 2 },
                                            { text: 'kontrolliert.. Anmerkung', id: 3 }
                                        ]}
                                        onChange={this.onChange_candidaturs_check}
                                        placeholder={'wähle aus..'}
                                    />
                                </div>
                            </div>
                            <div className="row modalDialogElement candidatur_send">
                                <div className="col-md-2">versendet am:</div>
                                <div className="col-md-10">
                                    <Toggle className="enableDate"
                                        onClick={this.onToggleGetSend}
                                        on="versendet am:"
                                        off="nicht versendet"
                                        size="sm"
                                        offstyle="danger"
                                        active={ !! this.state.content.candidaturs_send}
                                    />
                                    {
                                        this.showIfTrue(
                                            !! this.state.content.candidaturs_send,
                                            <Moment>{ this.showEditableValue( 'candidatur_send', this.state.content.candidaturs_send ) }</Moment>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row modalDialogElement candidatur_answer">
                                <div className="col-md-2">Antwort:</div>
                                <div className="col-md-10">
                                    <Toggle className="enableDate"
                                            onClick={this.onToggleGetAnswer}
                                            on="erhalten am:"
                                            off="keine Antwort"
                                            size="sm"
                                            offstyle="danger"
                                            active={ !! this.state.content.candidaturs_answer}
                                    />
                                    {
                                        this.showIfTrue(
                                            !! this.state.content.candidaturs_answer,
                                            <Moment>{ this.showEditableValue( 'candidatur_send', this.state.content.candidaturs_answer ) }</Moment>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="row modalDialogElement candidatur_failed">
                                <div className="col-md-2">Abgelehnt:</div>
                                <div className="col-md-10">
                                    <Toggle className="enableDate"
                                            onClick={this.onChange_candidaturs_regret}
                                            on="ja"
                                            off="nein"
                                            size="sm"
                                            onstyle="danger"
                                            active={ !! this.state.content.candidaturs_regret }
                                    />
                                </div>
                            </div>
                            <div className="row modalDialogElement candidatur_closed">
                                <div className="col-md-2">Geschlossen:</div>
                                <div className="col-md-10">
                                    <Toggle className="enableDate"
                                            onClick={this.onChange_candidaturs_closed}
                                            on="geschlossen"
                                            off="noch offen"
                                            size="sm"
                                            onstyle="danger"
                                            active={ !! this.state.content.closed }
                                    />
                                </div>
                            </div>
                            <div className="row modalDialogElement ownComment">
                                <div className="col-md-2">eigene Kommentare:</div>
                                <div className="col-md-10">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="ownCommentBox"
                                        onChange={this.onChange_comment}
                                        value={ this.state.content.kommentar ? this.state.content.kommentar : '' }
                                        rows="20" />
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
                <div className="dialogaction">
                    <Button
                        bsStyle="default"
                        onClick={(e) => {
                            this.onClickSwitchModus( 'view' );
                        }}
                    >Ansicht</Button>
                    <Button
                        bsStyle="default"
                        onClick={(e) => {
                            this.closeModal(e)
                        }}
                    >Verwerfen</Button>
                    <Button
                        bsStyle="primary"
                        onClick={(e) => {
                            this.onClickSwitchModus( 'closeANDsave' );
                        }}
                    >Übernehmen</Button>
                </div>
            </div>
        );
    }

    onClickSwitchModus( $viewMode ){

        switch( $viewMode ){

            case 'new':
                this.setState({modus: 'new'});
                break;
            case 'view':
                this.setState({modus: 'view'});
                break;
            case 'edit':
                this.copyProps2State();
                this.setState({modus: 'edit'});
                break;
            case 'closeANDsave':
                this.props.save2DB( this.state.content );
                this.closeModal();
                break;
            case 'close':
                if( this.state.modus === "view" ) {
                    this.props.saveComment2DB(this.state.content);
                }
                this.closeModal();
                break;
        }
    }

    onToggleGetSend() {

        let resultSet = this.state.content;

        if( this.state.content.candidaturs_send ) {
            resultSet.candidaturs_send = null;
        } else {
            resultSet.candidaturs_send = new moment().format('YYYY-MM-DD hh:mm');
        }

        this.setState({content: resultSet});
    }
    onToggleGetAnswer() {

        let resultSet = this.state.content;

        if( this.state.content.candidaturs_answer ) {
            resultSet.candidaturs_answer = null;
        } else {
            resultSet.candidaturs_answer = new moment().format('YYYY-MM-DD hh:mm');
        }

        this.setState({content: resultSet});
    }

    onChange_stars( newValue ){
        let state = this.state.content;
        state.stars = newValue;
        this.setState( {content: state} );
    }
    onChange_comment( event ){
        let state = this.state.content;
        state.kommentar = event.target.value;
        this.setState( {content: state} );
    }
    onChange_firmen_name( event ){
        let state = this.state.content;
        state.firmen_name = event.target.value;
        this.setState( {content: state} );
    }
    onChange_firmen_url( event ){
        let state = this.state.content;
        state.firmen_url = event.target.value;
        this.setState( {content: state} );
    }
    onChange_firmen_logo_url( event ){
        let state = this.state.content;
        state.firmen_logo_url = event.target.value;
        this.setState( {content: state} );
    }
    onChange_job( event ){
        let state = this.state.content;
        state.job = event.target.value;
        this.setState( {content: state} );
    }
    onChange_job_location( event ){
        let state = this.state.content;
        state.job_location = event.target.value;
        this.setState( {content: state} );
    }
    onChange_job_url( event ){
        let state = this.state.content;
        state.job_url = event.target.value;
        this.setState( {content: state} );
    }
    onChange_job_description( event ){
        let state = this.state.content;
        state.job_description = event.target.value;
        this.setState( {content: state} );
    }
    onChange_candidaturs( event ){
        let state = this.state.content;
        state.candidaturs = event.target.value;
        this.setState( {content: state} );
    }
    onChange_candidaturs_check( event ){
        let state = this.state.content;
        state.candidaturs_check = event.target.value === '' ? null : event.target.value;
        this.setState( {content: state} );
    }
    onChange_candidaturs_send( event ){
        let state = this.state.content;
        state.candidaturs_send = event.target.value;
        this.setState( {content: state} );
    }
    onChange_candidaturs_answer( event ){
        let state = this.state.content;
        state.candidaturs_answer = event.target.value;
        this.setState( {content: state} );
    }
    onChange_candidaturs_regret( event ){
        let state = this.state.content;
        state.candidaturs_regret = event ? "1" : null;
        this.setState( {content: state} );
    }
    onChange_candidaturs_closed( event ){
        let state = this.state.content;
        state.closed = event ? "1" : null;
        this.setState( {content: state} );
    }

    showCandidaturBrief(){
        if( this.state.content.candidaturs ){
            return <a target="_blank" href={ getBackendAdress( this.props.mode ) + '..'+this.state.content.candidaturs }>(Anschauen)</a>
        }
    }


    copyProps2State(){
        this.setState({content: this.props.content});
    }

    showCandidaturDetails(){
        switch( this.state.modus ){
            case 'new':
                return this.showCandidaturDetails_New();
                break;
            case 'view':
                return this.showCandidaturDetails_View();
                break;
            case 'edit':
                return this.showCandidaturDetails_Edit();
                break;
        }
    }

    render(){

        return (
            <div className="modalDialogCadidatur">

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal">
                    { this.showCandidaturDetails() }

                </Modal>
            </div>
        );
    }
}


export default dialogDetails;
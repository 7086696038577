import React, {Component} from 'react';

class ErrorDashletPage extends Component {

    render(){
        return <div>
            <h4>Fehler</h4>
            <p>Leider ist ein Fehler aufgetreten!</p>
        </div>;
    }
}

export default ErrorDashletPage;
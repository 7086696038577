import * as React from "react";

import classNames from "classnames";

export default class SidebarItem extends React.Component {

    render() {

        return (
            <div className={ classNames(
                this.props.item.type,
                this.props.selected ? ' selected' : '',
                this.props.isParentSelected || this.props.item.type === 'item' ? '' : 'hidden',
                this.props.highlighting ? 'highlight' : ''
            )}
                 onClick={this.props.onClick}
                 key={this.props.item.name}>
                <div className={ this.props.item.icon }></div>
                <div>{this.props.item.name}</div>
            </div>
        )
    }
}
import * as React from "react";
import axios from "axios";

export default class buildInformationElement extends React.Component {

    state = {
        build: {
            no: undefined,
            timestamp: undefined
        }
    };

    constructor(props) {
        super(props);

        this.getBuildInformation();
    }


    getBuildInformation() {

        axios.get('BUILDINFORMATION.json')

            .then(response =>
                    this.setState({build: response.data})

            ).catch(error =>
                console.error('sidebar', error)
        )
    }


    formatBuildtime = ($timestamp) => {

        if ($timestamp !== undefined) {

            var $year = $timestamp.substr(0, 4);
            var $month = $timestamp.substr(5, 2);
            var $day = $timestamp.substr(8, 2);

            var $time = $timestamp.substr(11);

            return $day + '.' + $month + '.' + $year + ' ' + $time;

        } else {

            return 'unknown';
        }
    };


    render() {
        return (
            <div className="buildInformation">
                {'BuildNo: ' + this.state.build.no + ' on ' + this.state.build.timestamp}
            </div>
        )
    }
}
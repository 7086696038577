import React, {Component} from 'react';
import {Button} from 'react-bootstrap/dist/react-bootstrap';

import './dashlet-configuration.css';

class DashletConfiguration  extends Component{


    constructor(props) {
        super(props);

        this.state = {
            typeOfDashlet: this.props.params.params.dashletType
        };
    }


    typeOfDashlets( $type ){

        switch( $type ){
            case 'statistic':
                return this.typeOfStatistics();
            case 'diagramm':
                return this.typesOfDiagramms();
            default:
                break;

        }
    }

    typeOfStatistics(){

        return (
            <select
                className="form-control"
                data-position={this.props.params.params.pos}
                onChange={this.props.fnChangeSQL.bind(this)}
                defaultValue={this.props.params.params.size}>

                <option>Allgemein</option>
                <option>Speziell (defekt)</option>
            </select>
        );
    }
    typesOfDiagramms(){

        return (
            <select
                className="form-control"
                data-position={this.props.params.params.pos}
                onChange={this.props.fnChangeSQL.bind(this)}
                defaultValue={this.props.params.params.size}>

                <option>Guthaben</option>
                <option>Einnahmen</option>
            </select>
        );
    }

    typeOfDashletName( $type ){

        switch( $type ){
            case 'statistic':
                return 'Statistiken';
            case 'diagramm':
                return 'Diagramm';
            default:
                break;
        }
    }

    render(){

        return (
            <div className="configuration-table">
                <div className="row">
                    <div className="col-md-4">
                        <label>Name des Dashlets:</label>
                    </div>
                    <div className="col-md-8 form-group">
                        <input className="form-control"
                               data-position={this.props.params.pos}
                               onChange={this.props.fnChangeName.bind(this)}
                               placeholder="Name vom Dashlet"
                               aria-describedby="basic-addon2"
                               defaultValue={this.props.params.params.name}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <label>Art der Darstellung:</label>
                    </div>
                    <div className="col-md-8 form-group">
                        <select
                            className="form-control"
                            data-position={this.props.params.params.pos}
                            onChange={this.props.fnChangeTyp.bind(this)}
                            defaultValue={this.typeOfDashletName( this.props.params.params.typ )}>

                            <option>Diagramme</option>
                            <option>Statistiken</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <label>Größe des Dashlets:</label>
                    </div>
                    <div className="col-md-8 form-group">
                        <select
                            className="form-control"
                            data-position={this.props.params.params.pos}
                            onChange={this.props.fnChangeSize.bind(this)}
                            defaultValue={this.props.params.params.size}>

                            <option>Normal</option>
                            <option>Doppelt</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <label>Dateninhalt:</label>
                    </div>
                    <div className="col-md-8 form-group">
                        {this.typeOfDashlets( this.props.params.params.typ )}
                    </div>
                </div>
                <div className="row dashlet-control-elements">
                    <div className="col-md-4" />
                    <div className="col-md-4">
                        <Button className="btn-success"
                                data-position={this.props.params.params.pos}
                                onClick={this.props.fnCreatDashlet}>erstellen</Button>
                    </div>
                    <div className="col-md-4" />
                </div>
                <div className="row dashlet-control-elements">
                    <div className="col-md-4">
                        <Button className="btn-info"><a className="glyphicon glyphicon-backward"/> verschieben</Button>
                    </div>
                    <div className="col-md-4">
                        <Button className="btn-danger"
                                data-position={this.props.params.params.pos}
                                onClick={this.props.fnDeleteDashlet}>löschen</Button>
                    </div>
                    <div className="col-md-4">
                        <Button className="btn-info">verschieben <a className="glyphicon glyphicon-forward"/></Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashletConfiguration;
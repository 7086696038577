import React, {Component} from 'react';
import logo from './logo.svg';
import logoWarning from './warning.png';
import './App.css';

class UnderConstruction extends Component {

    render() {
        return (
            <div>
                <div className="App">
                    <div className="App-header">
                        <img src={logo} className="App-logo" alt="logo"/>
                        <h2><i>'overview of finance & meters'</i></h2>
                        <h3>
                            <img src={logoWarning} className="warning-logo" alt="logo" />
                            this side is under construction
                            <img src={logoWarning} className="warning-logo" alt="logo" />
                        </h3>
                    </div>
                    <p className="App-intro">
                        To get started, edit <code>src/App.js</code> and save to reload.

                        <script src="__Sidebar/sidebar.js" type="text/jsx"></script>
                    </p>
                </div>
            </div>
        );
    }
}

export default UnderConstruction;

import React, {Component} from 'react';
import './App.css';
import './mobileDevices.css';

import axios from 'axios';
import Login from './__login/login';

import UnderConstruction from './underConstruction';
import Sidebar from './_Sidebar/sidebar';
import Content from './_Body/body';

import {NotificationContainer} from 'react-notifications';
import {NotificationManager} from 'react-notifications';
import 'bootstrap/dist/css/bootstrap.css';

class App extends Component {

    constructor(props){

        super(props);

        this.state = {
            page: 'system',
            visibleSidebar: true,
            devMode: 'unknown',
            loginSuccess: false,
            hasNewMessages: false
        };

        this.detectDevMode();
    }

    ShowUnderConstructionPage(){

        this.setState({page: 'UnderConstruction'});
    }

    OnPageChange( $page ){

        this.setState({ page: $page });
    }

    OnShowSidebarChange(){

        this.setState({ visibleSidebar: ! this.state.visibleSidebar });
    }

    OnChangeNewMessage( onoff ){
        if( this.state.hasNewMessages !== onoff ) {
            this.setState({hasNewMessages: onoff});
            document.title = onoff ? "* PrivateControllCenter()" : "PrivateControllCenter()";
        }
    }



    detectDevMode(){

        var $self = this;

        axios.get('development.json')

            .then( function(response){

                $self.setState( {dev: response.data.development} );
                $self.setState( {offline: response.data.offlineMode} );

                if( response.data.offline ){
                    $self.setState( { devMode: 'offline' } );
                    NotificationManager.warning( 'running in offline mode' );
	                this.setLoginSuccess();
                } else if( response.data.development ){
                    $self.setState( { devMode: 'dev' } );
                    NotificationManager.warning( 'running in development mode' );
                }

            })
            .catch( function(err){
                $self.setState( { devMode: 'prod' } )
            } )
    }

    setLoginSuccess(){
        this.setState({loginSuccess: true});
    }


    render() {

        document.title = "PrivateControllCenter()";

        switch(this.state.page){

            case 'UnderConstruction':
                return(<UnderConstruction/>);

            default:

                switch( this.state.loginSuccess ) {

                    case false:
                        return <Login
                                    devMode={this.state.devMode}
                                    setLoginSuccess={ () => this.setLoginSuccess() }
                            />;

                    case true:
                        return (
                            <div className="main">

                                <NotificationContainer/>
                                <Sidebar page={this.state.page}
                                         devMode={this.state.devMode}
                                         visibleSidebar={this.state.visibleSidebar}
                                         hasNewMessages={this.state.hasNewMessages}
                                         OnChangeNewMessage={this.OnChangeNewMessage.bind(this)}
                                         OnShowSidebarChange={(newPage) => this.OnShowSidebarChange(newPage)}
                                         OnPageChange={(newPage) => this.OnPageChange(newPage)}/>

                                <Content page={this.state.page}
                                         devMode={this.state.devMode}
                                         OnShowSidebarChange={(newPage) => this.OnShowSidebarChange(newPage)}
                                         OnPageChange={(newPage) => this.OnPageChange(newPage)}
                                         OnChangeNewMessage={this.OnChangeNewMessage.bind(this)}
                                         visibleSidebar={this.state.visibleSidebar}/>
                            </div>);
                }
                break;
        }
    }
}

export default App;

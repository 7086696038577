import React, {Component} from 'react';

import "./CurriculumVitae.css";
import passphoto from "./passphoto2.jpg";
import education from "./education.jpg";

import job from "./jobs.jpg";
import job_novedia from "./job_novedia.jpg";
import job_kcart from "./job_kcart.png";
import job_testo from "./job_testo.jpeg";
import job_agile42 from "./job_agile42.jpg";


class CirriculumVitae extends Component {

    constructor( props ){

        super( props );

        this.state = {
            collapse: {}
        };

        this._changeCollapseItem = this._changeCollapseItem.bind(this);
    }


    _getAge( birthday ){
        let today = new Date();
        var age = today.getFullYear() - birthday.getFullYear();

        if( today.getMonth() > birthday.getMonth() ||  today.getMonth() === birthday.getMonth() && today.getDay() >= birthday.getDay() ){
            return age;
        } else {
            return age-1;
        }
    }

    _changeCollapseItem( event ){
        this.setState( { collapse: {[event.target.id]: !this.state.collapse[event.target.id] }} );
    };
    _renderCollapse( id, change ){

        let state = this.state.collapse;

        if( state[id] ){
            state[id] = ! state[id];
        } else {
            state[id] = true;
        }

        if( change ){
            state[id] = ! state[id];
        }

        if( state[id] ) {
            return (<p className="glyphicon glyphicon-menu-down" id={id} onClick={ this._changeCollapseItem  }></p>);
        } else {
            return (<p className="glyphicon glyphicon-menu-up" id={id} onClick={ this._changeCollapseItem }></p>);
        }
    }

    _renderPersona(){
        return (<div className="cv-topic cv-persona">
            <div className="cv-head">Allgemein</div>
            <div className="row">
                <div className="col-md-9">
                    { this._renderPersonaDetail() }
                </div>
                <div className="col-md-3 passphoto">
                    <img src={ passphoto } style={{width: "80%"}} />
                </div>
            </div>
        </div> );
    }
    _renderPersonaDetail(){
        return (
            <div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-4 key" >Name</div>
                    <div className="col-md-8 value">Trautmann</div>
                </div>
                <div className="row">
                    <div className="col-md-4 key" >Vorame</div>
                    <div className="col-md-8 value">Dag</div>
                </div>
                <div className="row">
                    <div className="col-md-4 key" >Alter</div>
                    <div className="col-md-8 value">{ this._getAge( new Date("1978-12-02") ) + " Jahre" }</div>
                </div>
                <div className="row">
                    <div className="col-md-4 key" >Nationalität</div>
                    <div className="col-md-8 value">Deutsch</div>
                </div>
                <div className="row">
                    <div className="col-md-4 key" >Wohnort</div>
                    <div className="col-md-8 value">Berlin</div>
                </div>
                <div className="row">
                    <div className="col-md-4 key" >Führerschein</div>
                    <div className="col-md-8 value">Klasse B</div>
                </div>
            </div>);
    }

    _renderEducation(){
        return (<div className="cv-topic cv-education">
            <div className="cv-head">Bildung</div>
            <div className="row">
                <div className="col-md-9">
                    { this._renderEducationDetail() }
                </div>
                <div className="col-md-3 passphoto">
                    <img src={ education } style={{width: "80%"}} />
                </div>
            </div>
        </div>);
    }
    _renderEducationDetail(){
        return (
            <div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-1 key" >01.09.2003</div>
                    <div className="col-md-1 key" style={{textAlign: "center", fontSize: "x-small"}}>bis</div>
                    <div className="col-md-1 key" >31.05.2008</div>
                    <div className="col-md-1 key" ></div>
                    <div className="col-md-8 value">Studium - ”Dipl. Ing. (FH) technische Informatik”</div>
                </div>
                <div className="row">
                    <div className="col-md-1 key" >01.09.2001</div>
                    <div className="col-md-1 key" style={{textAlign: "center", fontSize: "x-small"}}>bis</div>
                    <div className="col-md-1 key" >31.08.2003</div>
                    <div className="col-md-1 key" ></div>
                    <div className="col-md-8 value">Studium - Elektrotechnik</div>
                </div>
                <div className="row">
                    <div className="col-md-1 key" >01.09.1996</div>
                    <div className="col-md-1 key" style={{textAlign: "center", fontSize: "x-small"}}>bis</div>
                    <div className="col-md-1 key" >31.01.2000</div>
                    <div className="col-md-1 key" ></div>
                    <div className="col-md-8 value">Berufsausbildung als Energieelektroniker</div>
                </div>
                <div className="row">
                    <div className="col-md-1 key" >01.09.1992</div>
                    <div className="col-md-1 key" style={{textAlign: "center", fontSize: "x-small"}}>bis</div>
                    <div className="col-md-1 key" >31.08.1996</div>
                    <div className="col-md-1 key" ></div>
                    <div className="col-md-8 value">Gesamtschule (Cottbus)</div>
                </div>
                <div className="row">
                    <div className="col-md-1 key" >01.09.1986</div>
                    <div className="col-md-1 key" style={{textAlign: "center", fontSize: "x-small"}}>bis</div>
                    <div className="col-md-1 key" >31.08.1992</div>
                    <div className="col-md-1 key" ></div>
                    <div className="col-md-8 value">Grundschule (Cottbus)</div>
                </div>
            </div>);
    }


    _renderJobs( data ){
        return (<div className="cv-topic cv-job">
            <div className="cv-head">{"Berufserfahrung - " + data.name }</div>
            <div className="row">
                <div className="col-md-9">{ this._renderJobDetail( data ) }</div>
                <div className="col-md-3 passphoto">
                    <img src={ data.photo } style={{width: "80%"}} />
                </div>
            </div>
            { this._renderMoreJobDetails( this.state.collapse[data.duration.from], data ) }
        </div>);
    }
    _renderJobDetail( data ) {
        return (
            <div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-1 key">{ this._renderCollapse( data.duration.from, true ) }</div>
                    <div className="col-md-1 key">{ data.duration.from }</div>
                    <div className="col-md-1 key" style={{textAlign: "center", fontSize: "x-small"}}>bis</div>
                    <div className="col-md-1 key">{ data.duration.to }</div>
                    <div className="col-md-1 key"></div>
                    <div className="col-md-7 value">{ data.job }</div>
                </div>
            </div>);
    }
    _renderMoreJobDetails( showMoreDetails, data ){

        if( showMoreDetails) {
            let details = [];

            data.details.forEach( function( entry ){
                details.push(
                    <div className="row" key={entry.topic}>
                        <div className="col-md-2">{entry.topic}</div>
                        <div className="col-md-6">{entry.content}</div>
                    </div>
                );
            });

            return (<div>{details}</div>);
        }
    }

    render(){
        return (<div className="curriculumVitea">
            { this._renderPersona() }
            { this._renderEducation() }


            { this._renderJobs( {
                name: "agile42 GmbH",
                photo: job_agile42,
                duration: {
                    from: "15.02.2018",
                    to: "bis heute"
                },
                job: "Software-Entwickler",
                details: [{
                    topic: "Tätigkeiten:",
                    content: "Entwicklung des eigenen Produktes mit React. Betreuung der Infrastruktur und support der Mitarbeiter und Kunden."
                },{
                    topic: "verwendete Technologien:",
                    content: "JavaScript, npm, React, Python, Django, Chef"
                },{
                    topic: "verwendete Werkzeuge:",
                    content: "PyCharm, Slack, RubyMine, Bash"
                }]
            } ) }


            { this._renderJobs( {
                name: "Testo SE & Co. KGaA",
                photo: job_testo,
                duration: {
                    from: "07.12.2015",
                    to: "30.11.2017"
                },
                job: "Software-Entwickler",
                details: [{
                    topic: "Tätigkeiten:",
                    content: "Entwicklung des eigenen Produktes der Firma Testo, Messgeräte in der Cloud. Es wurde versucht in mehreren Teams mittels Scrum die Produktvorstellung umzusetzen."
                },{
                    topic: "verwendete Technologien:",
                    content: "JavaScript, node.js, Grunt, Bower, require.js, react, pootle"
                },{
                    topic: "verwendete Werkzeuge:",
                    content: "IntelliJ, Jira, Slack, Bash"
                }]
            } ) }

            { this._renderJobs( {
                name: "Novedia Finance AG",
                photo: job_novedia,
                duration: {
                    from: "01.10.2012",
                    to: "31.03.2015"
                },
                job: "Software-Entwickler",
                details: [{
                    topic: "Tätigkeiten:",
                    content: "Entwickeln kundenspezifische Anwendungen in MS-Access als Frontend und Microsoft SQL als Backend. Direkte Abstimmung mit dem Kunden um das Produkt zu entwicklen und später Support zu ermöglichen. Später dann Testautomatisierung mittels MS-Test für das hauseigene Produkt. Dazu zählt TestCase-Verwaltung, Umsetzung und Erstellung."
                },{
                    topic: "verwendete Technologien:",
                    content: "Visal-Basic, MS-Test, Microsoft SQL, MS-Test, C#"
                }]
            } ) }

            { this._renderJobs( {
                name: "KCART GmbH",
                photo: job_kcart,
                duration: {
                    from: "09.08.2010",
                    to: "30.09.2012"
                },
                job: "Software-Entwickler",
                details: [{
                    topic: "Tätigkeiten:",
                    content: "Weiterentwicklung des hauseigenen Produktes in MS-Access als Frontend und Microsoft SQL als Backend. Abstimmung mit dem Produkteigentümer für die Umsetzung der neuen Features. Später dann auch Qualitätssicherung der täglichen Datenlieferung und Datenintegrität."
                },{
                    topic: "verwendete Technologien:",
                    content: "Visual-Basic, Microsoft SQL, C#"
                }]
            } ) }

            { this._renderJobs( {
                name: "Mail und Return GmbH",
                photo: job,
                duration: {
                    from: "01.12.2008",
                    to: "31.05.2010"
                },
                job: "Software-Entwickler",
                details: [{
                    topic: "Tätigkeiten:",
                    content: "Weiterführung und Verbesserung des Tagesgeschäftes zur Analyse der Kundendaten für Autohäuser. Später dann Datenbereinung der Eingangsdaten."
                },{
                    topic: "verwendete Technologien:",
                    content: "MySQL, Java, Bash-Programmierung"
                }]
            } ) }
    </div>);
    }
}

export default CirriculumVitae;
import React, {Component} from 'react';
import './system.css';

import axios from 'axios';
import {getBackendAdress, errorMessage} from '../../tools';

import HardDiskDrives from './harddiskdrives/harddiskdrives';
import ServerLogs from './serverlogs/serverlogs'
import CPULoad from './cpuload/cpuload';
import Backups from './backups/backups'

class system extends Component {


    constructor(props) {
        super(props);
        this.state = ({
            system: {},
            logs: {}
        });

        this._getAllSystemData( this.props.mode );

        if( this.timerID === undefined ) {
            this.timerID = setInterval(
                () => this._getAllSystemData( this.props.mode ),
                30000
            );
        }
    }

    componentWillReceiveProps(nextProps) {

        this._getAllSystemData();
    }

    componentDidMount(){

        this._getAllSystemData();
    }

    componentWillUnmount() {
        this.timerID = undefined;
        clearInterval(this.timerID);
    }

    _getAllSystemData(){

        var $self = this;

        if( this.timerID !== undefined ) {
            switch( this.props.mode ) {

                case 'offline':
                    axios.get('offline/systemspace.json')

                        .then(function (response) {

                            $self.setState({system: response.data});
                        }).catch(function (error) {

                        errorMessage(error, 'system');
                    });
                    axios.get('offline/systemlog.json')

                        .then(function (response) {

                            $self.setState({logs: response.data});
                        }).catch(function (error) {

                        errorMessage(error, 'system');
                    });
                    break;

                default:

                    axios.get( getBackendAdress( this.props.mode ) + 'php_system.php?part=harddiskdrives')

                        .then(function (response) {

                            $self.setState({system: response.data});
                        }).catch(function (error) {

                        errorMessage(error, 'system');
                    });

                    axios.get( getBackendAdress( this.props.mode ) + 'php_system.php?part=logs')

                        .then(function (response) {

                            $self.setState({logs: response.data});
                        }).catch(function (error) {

                        errorMessage(error, 'system');
                    });
                    break;
            }
        }
    }

    render() {
        return <div className="system" style={{flexFlow: true, float: 'left'}}>
            <HardDiskDrives data={this.state.system.harddiskdrive} />
            <ServerLogs     data={this.state.logs.serverlogs} />
            <CPULoad        mode={this.props.mode} coreCount={2}/>
            <Backups        mode={this.props.mode} />
        </div>;
    }
}

export default system;
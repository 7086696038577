import React, {Component} from 'react';

import OneDay from './dayCalendar';

import moment from 'moment';
import './monthCalendar.css';

class monthCalendar extends Component {

    _renderHead(){
        return <div className="row">
            <div className="col-md-1 cal-item"></div>
            <div className="col-md-1 cal-item"></div>
            <div className="col-md-1 cal-item"></div>
            <div className="col-md-1 cal-item cal-head-week">Mo</div>
            <div className="col-md-1 cal-item cal-head-week">Di</div>
            <div className="col-md-1 cal-item cal-head-week">Mi</div>
            <div className="col-md-1 cal-item cal-head-week">Do</div>
            <div className="col-md-1 cal-item cal-head-week">Fr</div>
            <div className="col-md-1 cal-item cal-head-week">Sa</div>
            <div className="col-md-1 cal-item cal-head-week">So</div>
        </div>
    }

    _monthName( $monthNumber ){
        switch( $monthNumber ){
            case 1: return "Januar";
            case 2: return "Februar";
            case 3: return "März";
            case 4: return "April";
            case 5: return "Mai";
            case 6: return "Juni";
            case 7: return "Juli";
            case 8: return "August";
            case 9: return "September";
            case 10: return "Oktober";
            case 11: return "Novenber";
            case 12: return "Dezember";
            default: return "";
        }
    }

    _renderNumber( month ){

        month--;
        var currentDate = moment( new Date( this.props.year, month, 1) );
        let week = [];
        let weeks = [];

        for( var a=1; a<=6; a++) {

            let kw = currentDate.isoWeek();
            for (var i = 1; i <= 7; i++) {

                if(i === currentDate.isoWeekday() && currentDate.month() === month) {
                    week[i] = currentDate.date();
                    currentDate.add(1, 'day');
                } else {
                    week[i] = null;
                }
            }

            if( a===1 || week[1] !== null ) {
                weeks.push(<div className="row" key={a}>
                    <div className="col-md-1 cal-item"></div>
                    <div className="col-md-1 cal-item"></div>
                    <div className="col-md-1 cal-item">
                        <div className="cal-item cal-kw">{kw}</div>
                    </div>
                    <OneDay
                        day={ week[1] }
                        month={ this.props.month}
                        year={ this.props.year }
                        holidays={ this.props.holidays }
                        options={ this.props.options }
                    />
                    <OneDay
                        day={ week[2] }
                        month={ this.props.month}
                        year={ this.props.year }
                        holidays={ this.props.holidays }
                        options={ this.props.options }
                    />
                    <OneDay
                        day={ week[3] }
                        month={ this.props.month}
                        year={ this.props.year }
                        holidays={ this.props.holidays }
                        options={ this.props.options }
                    />
                    <OneDay
                        day={ week[4] }
                        month={ this.props.month}
                        year={ this.props.year }
                        holidays={ this.props.holidays }
                        options={ this.props.options }
                    />
                    <OneDay
                        day={ week[5] }
                        month={ this.props.month}
                        year={ this.props.year }
                        holidays={ this.props.holidays }
                        options={ this.props.options }
                    />
                    <OneDay
                        day={ week[6] }
                        month={ this.props.month}
                        year={ this.props.year }
                        holidays={ this.props.holidays }
                        options={ this.props.options }
                    />
                    <OneDay
                        day={ week[7] }
                        month={ this.props.month}
                        year={ this.props.year }
                        holidays={ this.props.holidays }
                        options={ this.props.options }
                    />
                </div>);
            }
        }
        return weeks;
    }

    render() {
        return <div className="month">
            <div className="month-name">{ this._monthName( this.props.month ) }</div>
            <div className="month-calendar" style={{minHeight: "180px"}}>
                { this._renderHead() }
                { this._renderNumber( this.props.month) }
            </div>
        </div>;
    }
}

export default monthCalendar;
import React, {Component} from 'react';
import './body.css';

import Finance from './_financeOverview/finance';
import FinanceImport from './_financeOverview/import/importModal';
import Pension from './_financeOverview/pension/pension';
import CountDown from './_experiment/countdown';
import Candidatur from './_candidatur/candidatur';
import CandidaturCal from './_candidatur/calendar';
import CandidaturCV from './_candidatur/CurriculumVitae/CurriculumVitae';
import System from './_system/system';
import Bookmarks from './_else/bookmarks/bookmarks';
import Chat from './_else/chat/chat';
import KeyValue from './_else/keyValue/keyValue';
import Calendar from './_calendar/calendar';
import Usersettings from "../__login/usersettings";
import Entwicklung from "../_Body/_entwicklung/entwicklung";

import {getLastChatID,getUsername} from "../_Body/_else/chat/chatAPI";

class Content extends Component {


    constructor(props) {
        super(props);

        this.state = {
            title: '',
            page: '',
            chatLastMessageID: -1,
            chatLastMessageID2: -1,
            chatUsersOnline: ""
        };

        if( this.timerID === undefined ) {
            this.timerID = setInterval(
                () => this.tick(),
                1000
            );
        }
    }

    tick(){
        getLastChatID( this.props.devMode, this.state.chatLastMessageID, this.newMessage.bind(this) );
        if( document.getElementsByClassName("chat_messages").length &&
            this.state.chatLastMessageID !== this.state.chatLastMessageID2) {

            document.getElementsByClassName("chat_messages")[0].scrollTop = 10000000;
            this.setState({chatLastMessageID2: this.state.chatLastMessageID});
        }
    }
    newMessage( response ){

        if( response.id !== this.state.chatLastMessageID ) {
            this.props.OnChangeNewMessage( response.username !== getUsername() );
            if( response.username !== getUsername() ){
                document.getElementById("audio").play();
            }
        }
        if( this.state.chatLastMessageID !== response.id ) {
            this.setState({chatLastMessageID: response.id});
        }
        if( this.state.chatUsersOnline !== response.online_user ) {
            this.setState({chatUsersOnline: response.online_user});
        }
    }

    componentWillMount() {

    }

    componentWillReceiveProps(){

        this.chooseDashlet( this.props.page );
        this.forceUpdate();
    }

    setTopic( $title ) {

        if( $title !== this.state.title ) {
            this.setState({title: $title});
        }
    }

    chooseDashlet( $page ){
        
        this.setState({
            page: $page,
            title: $page
        });
    }

    getTopic( $page ){

        switch( $page ){
            case 'usersettings':
                return 'Benutzereinstellungen';
            case 'finance':
                return 'Finanzübersicht';
            case 'finance.import':
                return this.getTopic('finance');
            case 'finance.pension':
                return 'Rente';
            case 'experiment.countdown':
                return 'CountDown (Experiment)';
            case 'system':
                return 'Systemübersicht';
            case 'else':
                return 'Lesezeichen';
            case 'chat':
                return 'Chat';
            case 'else.keys':
                return 'Schlüssel';
            case 'candidatur':
                return 'Bewerbungen';
            case 'candidatur.cv':
                return 'Lebenslauf';
            case 'candidatur.calendar':
                return 'Termine';
            case 'candidatur.old':
                return 'Dokumente';
            case 'calendar':
                return 'Kalender';
            case 'entwicklung':
                return 'Entwicklung';
            default:
                return $page;
        }
    }

    getContent( $page ){

        switch( $page ){

            case 'usersettings':
                return (
                    <Usersettings
                        mode={this.props.devMode}
                        offlineMode={this.props.offlineMode}
                    />);

            case 'finance':
                return (
                    <Finance
                        setTitle={(body) => this.setTopic(body)}
                        mode={this.props.devMode}
                        offlineMode={this.props.offlineMode}
                    />);

            case 'finance.import':
                return (
                    <FinanceImport
                        setTitle={(body) => this.setTopic(body)}
                        mode={this.props.devMode}
                        OnPageChange={this.props.OnPageChange}
                    />);

            case 'finance.pension':
                return (
                    <Pension
                        setTitle={(body) => this.setTopic(body)}
                        mode={this.props.devMode}
                        OnPageChange={this.props.OnPageChange}
                    />);

            case 'experiment.countdown':
                return (
                    <CountDown
                        setTitle={(body) => this.setTopic(body)}
                        mode={this.props.devMode}
                        OnPageChange={this.props.OnPageChange}
                    />);

            case 'candidatur':
                return (
                    <Candidatur
                        mode={this.props.devMode}
                    />
                );
            case 'candidatur.cv':
                return (
                    <CandidaturCV
                        mode={this.props.devMode}
                    />
                );
            case 'candidatur.calendar':
                return (
                    <CandidaturCal
                        mode={this.props.devMode}
                    />
                );
            case 'candidatur.old':
                return (
                    <iframe className='iframe-theme'
                        src="/Bewerbungen/trautmann.d/04_Bewerbungen_2015/">(zu alten Daten)</iframe>
                );

            case 'system':
                return (
                    <System
                        mode={this.props.devMode}
                    />
                );

            case 'chat':
                return (
                    <Chat
                        mode={this.props.devMode}
                        lastChatID={this.state.chatLastMessageID}
                    />
                );

            case 'else':
                return (
                    <Bookmarks
                        mode={this.props.devMode}
                    />
                );
            case 'else.keys':
                return (
                    <KeyValue
                        mode={this.props.devMode}
                    />
                );

            case 'calendar':
                return (
                    <Calendar
                        mode={this.props.devMode}
                        options={{
                            showHolidays: true,
                        }}
                    />
                );

            case 'entwicklung':
                return (
                    <Entwicklung
                        mode={this.props.devMode}
                    />
                );
            default:
                return 'Es gibt noch keinen Inhalt.';
        }
    }

    getIconShowSidebar( $isVisible ){

        if( $isVisible) {
            return <a className="glyphicon glyphicon-triangle-right" onClick={ this.props.OnShowSidebarChange }></a>;
        } else {
            return <a className="glyphicon glyphicon-triangle-left" onClick={ this.props.OnShowSidebarChange }></a>
        }
    }

    hasSidebarCSS( $sidebarIsVisible ){
        return $sidebarIsVisible ? ' hasSidebar' : '';
    }

    renderOnlineUser(){
        let onlineUsers = [];
        if( this.state.chatUsersOnline.includes(',') ){
            this.state.chatUsersOnline.split(',').map( (item) => {
                if( item.length > 0 ) {
                    onlineUsers.push(<div className="onlineUser" key={item}><font style={{color: 'lightgreen'}}>@</font>{item}</div>);
                }
            });
        } else {
            onlineUsers.push(<div className="onlineUser" key={this.state.chatUsersOnline}><font style={{color: 'lightgreen'}}>@</font>{this.state.chatUsersOnline}</div>);
        }

        return onlineUsers;
    }

    render() {

        return (
            <div className={ 'content' + this.hasSidebarCSS( this.props.visibleSidebar ) } >
                <div className="onlineUsers"><div>{ this.renderOnlineUser() }</div></div>
                <h1>
                    { this.getIconShowSidebar( this.props.visibleSidebar ) }
                    { this.getTopic( this.props.page ) }
                </h1>
                { this.getContent( this.props.page )}
            </div>
        )
    }
}

export default Content;
import React, {Component} from 'react';
import axios from 'axios';
import { getBackendAdress, errorMessageDashlet } from '../../../tools';

import offlineData_dashletConfigurations from './dashletdiagramm.config.demo.json'

import { Chart } from 'react-google-charts';
import DashletConfiguration from './dashlet-configuration';
import ErrorDashlet from '../errorDashletPage';

import animationSpinner from './spinner.gif';

class DashletDiagramm extends Component {


    constructor(props) {
        super(props);

        this.state = {
            params: this.props.params,
            data: {},
            error: false
        };

        if( this.timerID === undefined ) {
            this.timerID = setInterval(
                () => this.tick(),
                10000
            );
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        if( this.timerID !== undefined ) {
            clearInterval(this.timerID);
        }
    }

    componentWillReceiveProps(nextProps) {

        this.getDataFromBackend( nextProps.params.params.year );
    }


    showDashlet( $view ){

        switch( $view ){
            case 'main':

                if( this.state.error ) {
                    return <ErrorDashlet/>
                } else {
                    return this.showGraph();
                }
            case 'configuration':

                return <DashletConfiguration
                    fnFlip={(me) => this._flip(me)}
                    fnChangeTyp={this.props.fnChangeTyp}
                    fnChangeName={this.props.fnChangeName}
                    fnChangeSize={this.props.fnChangeSize}
                    fnChangeSQL={this.props.fnChangeSQL}
                    fnDeleteDashlet={this.props.fnDeleteDashlet}
                    fnCreatDashlet={this.props.fnCreatDashlet}
                    params={this.props.params}
                    pos={this.props.pos}
                />;
            default:
                return <p>Fehler</p>;
        }
    }


    showGraphExample(){

        return (
            <Chart
                chartType="LineChart"
                data={[['Monate','Guthaben (€)'],
                    ['Jan', 1000],
                    ['Feb', 1500],
                    ['Mar', 1700],
                    ['Apr', 1800],
                    ['Mai', 1500],
                    ['Jun', 1200],
                    ['Jul', 1500],
                    ['Aug', 1700],
                    ['Sep', 2000],
                    ['Okt', 2200],
                    ['Nov', 2200],
                    ['Dec', 2300]
                ]}
                options={{
                    legend: 'none'
                }}
                loader={<div className="dashlet-spinner" style={{marginTop: '90px', width: '100%', textAlign: 'center'}}><img src={animationSpinner} /></div>}
                width="100%"
                height="280px"
                legend_toggle
            />);
    }

    showGraph(){

        let seriesData = this.getChartDataSerie();

        if( seriesData.length > 1 ) {
            return (
                <Chart
                    chartType="LineChart"
                    data={ seriesData }
                    options={{
                        legend: 'none',
                        vAxis: {
                            minValue: 0,
                            maxValue: 12
                        },
                        hAxis: {
                            minValue: 0
                        }
                    }}
                    loader={<div className="dashlet-spinner" style={{marginTop: '90px', width: '100%', textAlign: 'center'}}><img src={animationSpinner} /></div>}
                    width="100%"
                    height="280px"
                    legend_toggle
                />);
        } else {
            return <div className="noDataDashlet">
                    <div className="glyphicon glyphicon-ban-circle dashlet-icon"></div>
                    <div>No Data</div>
                </div>;
        }


    }

    tick(){
        this.getDataFromBackend( this.props.params.params.year );
    }

    getChartDataSerie(){

        var $data = [['Monate','Betrag (€)','Betrag2 (€)']];


        for(var $i=0; $i < Object.keys(this.state.data).length; $i++){

            let $iterativeMonth = $data.length ? $data.length : 1;

            while($iterativeMonth < parseInt( this.state.data[$i].month ) ){
                $data.push( [ this.getShortMonthNameByIndex( $iterativeMonth-1 ), null,null ] );
                $iterativeMonth++;
            }
            $data.push( this.getPointForDiagramm( this.state.data[$i] ) );
        }

        return $data;
    }

    getPointForDiagramm( $dataPoint ){

        return [
            this.getShortMonthNameByIndex( parseInt($dataPoint.month)-1 ),
            parseFloat( $dataPoint.value ),
            parseFloat( $dataPoint.value2 )
        ];
    }

    getShortMonthNameByIndex( $index ){
        switch( $index ){
            case 0: return 'Jan';
            case 1: return 'Feb';
            case 2: return 'Mar';
            case 3: return 'Apr';
            case 4: return 'Mai';
            case 5: return 'Jun';
            case 6: return 'Jul';
            case 7: return 'Aug';
            case 8: return 'Sep';
            case 9: return 'Okt';
            case 10: return 'Nov';
            case 11: return 'Dez';
            default:
                break;
        }
    }

    getRequestParam( $dashletType ){

        switch( $dashletType ){
            case 'Guthaben':
                return 'question=getMoneyIncrement';
            case 'Gehalt':
                return 'question=getMoneySalery';
            case 'Ausgaben':
                return 'question=getMoneyOutgoing';
            case 'Gespart':
                return 'question=getMoneySave';
            case 'Miete':
                return 'question=getMoneyRentHome';
            case 'Gas':
                return 'question=getMoneyRentHomeGas';
            case 'Strom':
                return 'question=getMoneyRentHomeElectricity';
            case 'Internet':
                return 'question=getMoneyRentHomeInternet';
            case 'Mobile':
                return 'question=getMoneyRentHomeMobile';
            case 'GEZ':
                return 'question=getMoneyRentHomeGEZ';
            case 'violine':
                return 'question=getMoneyRentVioline';
            default:
                break;
        }
    }

    getDataFromBackend( $year ){

        var $self = this;


        switch( this.props.mode ) {

            case 'offline':
                $self.setState({data: offlineData_dashletConfigurations});
                break;

            default:

                axios.get( getBackendAdress( this.props.mode ) + 'php_bank.php?' + this.getRequestParam(this.props.params.params.dashletType) + '&year=' + $year)

                    .then(function (response) {

                        $self.setState({data: response.data});
                        $self.setState({error: false});

                    }).catch(function (error) {

                        errorMessageDashlet(error, $self.props.params.params);
                        $self.setState({error: true});
                    });
                break;
        }
    }



    render() {

        return this.showDashlet( this.props.params.side );
    }
}

export default DashletDiagramm;
import React, {Component} from 'react';
import './serverlogs.css';


class serverlogs extends Component {

    showServerLogs(){
        let logs = [];

        if( this.props.data ) {

            let serverlogs = this.props.data;

            for (let idx = 0; idx < serverlogs.length; idx++) {

                if( serverlogs[idx].date.length > 0 ) {

                    logs.push(
                        <div className="row log data" key={idx}>
                            <div className="col-md-3">{serverlogs[idx].date}</div>
                            <div className="col-md-2">{serverlogs[idx].machine}</div>
                            <div className="col-md-6">{serverlogs[idx].message}</div>
                        </div>
                    );
                }
            }
        }

        return <div>{logs}</div>;
    }

    render() {
        return <div className="serverlogs">
            <div className="row log head">
                <div className="col-md-3">Datum</div>
                <div className="col-md-2">Maschine</div>
                <div className="col-md-6">Nachricht</div>
            </div>
            <div className="content">
                {this.showServerLogs()}
            </div>
        </div>;
    }
}

export default serverlogs;
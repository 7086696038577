import DialogDetails from './dialogDetails/dialogDetails';

import React, {Component} from 'react';
import './candidatur.css';
import '../../__ext_tools/daterangepicker.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import axios from 'axios';
import {Button} from 'react-bootstrap/dist/react-bootstrap';
import ReactStars from 'react-stars';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Moment from 'react-moment';

import {getBackendAdress, errorMessage, NotificationManager} from '../../tools';
import GoodOrBad from './../../__ext_tools/GoodOrBad';

var moment = require('moment');
Moment.globalLocale = 'de';
Moment.globalFormat = 'DD MMM YYYY HH:mm';



class Candidatur extends Component {


    constructor(props) {
        super(props);
        this.state = {
            showDetails: -1,
            candidaturs: [],
            filter: 'neu',
            dateRangePicker: {
                start: moment().subtract(2, 'month').startOf('month'),
                end: moment()
            }
        };

        this._onChange_DateRange = this._onChange_DateRange.bind(this);
    }

    componentWillReceiveProps(nextProps) {

        this._getAllCandidatur( nextProps.mode );
    }


    componentDidMount(){

        this._getAllCandidatur( this.props.mode );
    }

    clickDetails( $id ){
        this.setState({
            showDetails: $id
        });
    }

    showDetails(){
        if( this.state.showDetails > 0 ){
            return (
                <DialogDetails
                    onClose={ () => this.closeDetails(this)}
                    content={ this.state.candidaturs.find( item => item.id === this.state.showDetails ) }
                    save2DB={ this.save2DB.bind(this) }
                    saveComment2DB={ this.saveComment2DB.bind(this) }
                    mode={ this.props.mode }
                />
            )
        }
        if( this.state.showDetails === 0 ){
            return (<DialogDetails
                onClose={ () => this.closeDetails(this)}
                save2DB={ this.save2DB.bind(this) }
                saveComment2DB={ this.saveComment2DB.bind(this) }
            />);
        }
        return;
    }

    closeDetails( $self ){
        $self.setState({
            showDetails: -1
        });
    }

    showFilterPanel() {
        return (
            <div>
                <Button
                    className="create_new glyphicon glyphicon-star-empty"
                    onClick={ () => this.clickDetails( 0 ) } />
                <div className="btn-group candidatur-navigation">
                    <Button
                        className={this._createClassName('alle')}
                        onClick={ () => this.setState({filter: 'alle'})}
                    >
                        <div className='glyphicon glyphicon-eye-open' />
                        alle
                    </Button>
                    <Button
                        className={this._createClassName('neu')}
                        onClick={ () => this.setState({filter: 'neu'})}
                    >
                        <div className='glyphicon glyphicon-star-empty' />
                        neu
                    </Button>
                    <Button
                        className={this._createClassName('vorbereitet')}
                        onClick={ () => this.setState({filter: 'vorbereitet'})}
                    >
                        <div className='glyphicon glyphicon-repeat' />
                        vorbereitet
                    </Button>
                    <Button
                        className={this._createClassName('versendet')}
                        onClick={ () => this.setState({filter: 'versendet'})}
                    >
                        <div className='glyphicon glyphicon-send' />
                        versendet
                    </Button>
                    <Button
                        className={this._createClassName('geantwortet')}
                        onClick={ () => this.setState({filter: 'geantwortet'})}
                    >
                        <div className='glyphicon glyphicon-inbox' />
                        geantwortet
                    </Button>
                    <Button
                        className={this._createClassName('offen')}
                        onClick={ () => this.setState({filter: 'offen'})}
                    >
                        <div className='glyphicon glyphicon-transfer' />
                        offen
                    </Button>
                    <Button
                        className={this._createClassName('abgesagt')}
                        onClick={ () => this.setState({filter: 'abgesagt'})}
                    >
                        <div className='glyphicon glyphicon-trash' />
                        abgesagt
                    </Button>
                    <DateRangePicker
                        apply='Übernehmen'
                        showISOWeekNumbers={true}
                        locale={{
                            format: 'DD.MM.YYYY',
                            applyLabel: 'Übernehmen',
                            cancelLabel: 'Verwerfen',
                            customRangeLabel: 'individuell',
                            daysOfWeek: ['So', 'Mo', 'Tu', 'We', 'Th', 'Fr','Sa'],
                            monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                            firstDay: 1
                        }}
                        ranges={{
                            'Letzte 7 Tage': [moment().subtract(6, 'days'), moment()],
                            'Diesen Monat': [moment().startOf('month'), moment().endOf('month')],
                            'Letzter Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            'Letzten 3 Monate': [moment().subtract(2, 'month').startOf('month'), moment().endOf('month')]
                        }}
                        startDate={ this.state.dateRangePicker.start }
                        endDate={ this.state.dateRangePicker.end }
                        onApply={ this._onChange_DateRange }>
                        <Button className='btn-calendar'>
                            <div className='glyphicon glyphicon-calendar' />
                            {new Date(this.state.dateRangePicker.start).toLocaleDateString()} - {new Date(this.state.dateRangePicker.end).toLocaleDateString()}
                        </Button>
                    </DateRangePicker>
                    <Button
                        className={this._createClassName('closed')}
                        onClick={ () => this.setState({filter: 'closed'})}
                    >
                        <div className='glyphicon glyphicon-fire' />
                        geschlossen
                    </Button>
                </div>
            </div>);
    }

    render_tableContent(){

        let candidaturs = [];

        this.state.candidaturs

            .filter( entry => {
                if( this.state.filter === 'neu' ){
                    return entry.candidaturs === null && entry.candidaturs_send === null && entry.candidaturs_answer === null && entry.closed === null
                } else return true })
            .filter( entry => {
                if( this.state.filter === 'vorbereitet' ){
                    return entry.candidaturs !== null && entry.candidaturs_send === null
                } else return true })
            .filter( entry => {
                if( this.state.filter === 'versendet' ){
                    return entry.candidaturs_send !== null && entry.candidaturs_answer == null
                } else return true })
            .filter( entry => {
                if( this.state.filter === 'geantwortet' ){
                    return entry.candidaturs_answer !== null
                } else return true })
            .filter( entry => {
                if( this.state.filter === 'offen' ){
                    return entry.candidaturs_answer !== null && entry.candidaturs_regret !== "1"
                } else return true })
            .filter( entry => {
                if( this.state.filter === 'abgesagt' ){
                    return entry.candidaturs_answer !== null && entry.candidaturs_regret === "1"
                } else return true })
            .filter( entry => {
                if( this.state.filter === 'closed' ){
                    return entry.closed === '1'
                } else return true })

            .map( (candidatur,i) => {

                var rejected = candidatur.candidaturs_regret==="1"?"candidatur_rejected":"";
                var newItem = moment().diff(moment(candidatur.created), "days") <= 1 ? ' pulse' : '';

                candidaturs.push(
                    <div className={"row candidatur-content "+rejected + newItem} onClick={ (e) => { this.clickDetails( candidatur.id ) } } key={ i }>
                        <div className="col-sm-1"><Moment>{ candidatur.created }</Moment></div>
                        <div className="col-sm-1">{ candidatur.job }<br/>
                            <ReactStars
                                count={5}
                                size={15}
                                value={ parseInt( candidatur.stars ) }
                                color2={'#ffd700'} />
                        </div>
                        <div className="col-sm-1">{ candidatur.firmen_name }</div>
                        <div className="col-sm-1">{ candidatur.job_location }</div>
                        <div className="col-sm-4">{
                            candidatur.job_description && candidatur.job_description.length >= 200 ? candidatur.job_description.substr(0, 200) + '...' : candidatur.job_description
                        }</div>
                        <div className="col-sm-1">
                            { candidatur.candidaturs_send ? <Moment format="DD. MMM YYYY">{candidatur.candidaturs_send}</Moment> :null }
                        </div>
                        <div className="col-sm-1">
                            <GoodOrBad value={candidatur.candidaturs_check}/>
                        </div>
                        <div className="col-sm-2">
                            { candidatur.candidaturs_answer
                                ? <div className="candidatur_list_answer" style={{textAlign: "center"}}>
                                    <Moment format="DD. MMM YYYY">{candidatur.candidaturs_answer}</Moment>
                                    <GoodOrBad value={candidatur.candidaturs_regret ? "3" : null}/>
                                </div>
                                :null }
                        </div>
                    </div>);
                return;
            });
        return candidaturs;
    }


    _onChange_DateRange( event, picker ){
        this.setState({
            dateRangePicker: {
                start: picker.startDate,
                end: picker.endDate
            }
        });
        this._getAllCandidatur( this.props.mode );
    }

    _createClassName( $buttonName ){
        if( this.state.filter !== null && this.state.filter.indexOf( $buttonName ) >= 0 ){
            return "btn-success selected";
        } else {
            return "btn-success"
        }
    }

    _getAllCandidatur(){

        var $self = this;

        switch( this.props.mode ) {

            case 'offline':
                axios.get( 'offline/candidatur.json' )

                    .then(function (response) {

                        $self.setState({candidaturs: response.data});
                    }).catch(function (error) {

                    errorMessage(error, 'candidatur');
                });
                break;

            default:

                axios.get( getBackendAdress( this.props.mode ) + 'php_candidatur.php?part=getAll'
                    +'&startDate='+this.state.dateRangePicker.start.format('YYYYMMDD000000')
                    +'&endDate='+this.state.dateRangePicker.end.format('YYYYMMDD235959'))

                    .then(function (response) {

                        $self.setState({candidaturs: response.data});

                    }).catch(function (error) {
                        errorMessage(error, 'candidatur');
                });
                break;
        }
    }
    saveComment2DB( $newData ) {

        if ($newData.id) {

            switch( this.props.mode ){

                case 'offline':
                    break;

                default:
                    var self = this;
                    var $requestData = encodeURIComponent( JSON.stringify($newData) );
                    $requestData = 'data=' + $requestData;

                    axios.post( getBackendAdress( this.props.mode ) + 'php_candidatur.php?part=saveComments', $requestData )

                        .then(function (response) {

                            if (response.data.result === "success") {
                                NotificationManager.success('Speichere Daten', 'Bewerbungen');
                            } else {
                                NotificationManager.error('Daten nicht gespeicher', 'Bewerbungen');
                            }
                            self._getAllCandidatur(self.props.mode);
                        })
                        .catch((function (error) {

                            console.log(error);
                            NotificationManager.error('Daten nicht gespeichert', 'Bewerbungen');
                        }));
            }
        } else {
            NotificationManager.error('Datensatz nicht gefunden', 'Bewerbungen');
        }
    }
    save2DB( $newData ){

        var self = this;
        var $requestData = encodeURIComponent( JSON.stringify($newData) );
        $requestData = 'data=' + $requestData;

        if( $newData.id > 0 ){

            switch( this.props.mode ){

                case 'offline':
                    break;

                default:
                    axios.post( getBackendAdress( this.props.mode ) + 'php_candidatur.php?part=saveEntry', $requestData )

                    .then(function (response){

                        if( response.data.result === "success" ) {
                            NotificationManager.success('Speichere Daten', 'Bewerbungen');
                        } else {
                            NotificationManager.error( 'Daten nicht gespeicher', 'Bewerbungen' );
                            NotificationManager.error( response.data.errorMessage );
                        }
                        self._getAllCandidatur( self.props.mode );
                    })
                    .catch((function (error){

                        console.log( error );
                        NotificationManager.error( 'Daten nicht gespeichert', 'Bewerbungen');
                    }));
                    break;
            }

        } else {
            if( $newData.firmen_name === null ){
                NotificationManager.error( 'wurde nicht erstellt', 'Bewerbungen' );
            } else {

                switch( this.props.mode ){

                    case 'offline':
                        break;

                    default:
                        axios.post( getBackendAdress( this.props.mode ) + 'php_candidatur.php?part=createEntry', $requestData )

                            .then(function (response) {

                                if (response.data.result === "success") {
                                    NotificationManager.success('lege neues Angebot an', 'Bewerbungen');
                                } else {
                                    NotificationManager.error('konnte Angebot nicht speichern', 'Bewerbungen');
                                    NotificationManager.error( response.data.errorMessage, 'Bewerbungen');
                                    console.log( 'Fehler:', response.data.errorMessage );
                                }
                                self._getAllCandidatur(self.props.mode);
                            })
                            .catch((function (error) {

                                console.log(error);
                                NotificationManager.error('konnte Angebot nicht speichern', 'Bewerbungen');
                            }));
                        break;
                }

            }
        }
    }

    showStatusBar( filterCount ){
        return <div className="statusBar">gefundene Einträge: {this.state.candidaturs.length}, gefiltert: {filterCount}</div>
    }

    render() {
        let renterTableContent = this.render_tableContent();
        return (
            <div>
                {this.showDetails()}
                { this.showFilterPanel() }
                { this.showStatusBar( renterTableContent.length ) }
                <div className="candidatur">
                    <div className="row candidatur-header">

                        <div className="col-sm-1">erstellt</div>
                        <div className="col-sm-1">Job</div>
                        <div className="col-sm-1">Firma</div>
                        <div className="col-sm-1">Ort</div>
                        <div className="col-sm-4">Beschreibung</div>
                        <div className="col-sm-1">verschickt</div>
                        <div className="col-sm-1">kontrolliert</div>
                        <div className="col-sm-2" style={{textAlign: "center"}}>Antwort</div>
                    </div>
                    { renterTableContent }
                </div>
            </div>);
    }
}

export default Candidatur;
import React, {Component} from 'react';
import axios from 'axios';
import './finance.css';
import offlineData_dashletConfigurations from './finance.config.demo.json'

import Dashlet from './widgets/dashlet';
import {Button} from 'react-bootstrap/dist/react-bootstrap';
import {getBackendAdress, errorMessage} from '../../tools';


class Finance extends Component {


    constructor(props) {
        super(props);

        this.state = {
            year: new Date().getFullYear(),
            dashlets: []
        };
        this.getCurrentConfiguration( this.props.mode );
    }

    componentWillReceiveProps(nextProps) {

        this.getCurrentConfiguration( nextProps.mode );
    }

    getCurrentConfiguration( mode ){

        var $self = this;

        switch( mode ){

            case 'offline':
                this.setState( { dashlets: offlineData_dashletConfigurations } ) ;
                break;

            default:


                axios.get( getBackendAdress( this.props.mode ) + 'php_bank.php?question=getDashletConfiguration')

                    .then(function (response) {

                        $self.setState({dashlets: response.data});
                        $self.setState({error: false});

                    }).catch(function (error) {

                    errorMessage(error, 'finance.js');
                    $self.setState({error: true});
                });
                break;
        }
    }

    eventChangeTyp(e){

        var dashlets = this.state.dashlets;

        var elementText = e.target[e.target.selectedIndex].text;
        var dashletPosition = e.target[e.target.selectedIndex].parentElement.getAttribute('data-position');

        switch( elementText ){
            case 'Statistiken':
                dashlets[dashletPosition].typ = 'statistic';
                break;
            case 'Diagramme':
                dashlets[dashletPosition].typ = 'diagramm';
                break;
            default:
                console.error( 'unbekannter Typ' );
        }
    }

    eventChangeName(e){

        var dashlets = this.state.dashlets;

        var elementText = e.target.value;
        var dashletPosition = e.target.getAttribute('data-position');

        dashlets[dashletPosition].name = elementText;

        this.setState( {dashlets: dashlets} );
    }

    eventChangeSize(e){

        var dashlets = this.state.dashlets;

        var elementText = e.target[e.target.selectedIndex].text;
        var dashletPosition = e.target[e.target.selectedIndex].parentElement.getAttribute('data-position');


        dashlets[dashletPosition].size = elementText === 'Doppelt' ? 2 : 1;

        this.setState( {dashlets: dashlets} );
    }

    eventChangeSQL(e){

        var dashlets = this.state.dashlets;

        var elementText = e.target.value;
        var dashletPosition = e.target.getAttribute('data-position');

        dashlets[dashletPosition].sql = elementText;

        this.setState( {dashlets: dashlets} );
    }

    eventSubYear(){
        if( this.state.year > 2013 ) {
            this.setState({year: this.state.year - 1});
        }
    }
    eventAddYear(){
        if( this.state.year < new Date().getFullYear() ) {
            this.setState({year: this.state.year + 1});
        }
    }

    eventDeleteDashlet(e){
        console.log( 'löschen Dashlet', e.target.getAttribute('data-position') );
        var dashletPosition = e.target.getAttribute('data-position');

        var dashlets = this.state.dashlets;
        dashlets.splice(dashletPosition, 1);
        this.setState( {dashlets: dashlets} );
        this.forceUpdate();
    }
    eventCreateDashlet(e){
        console.log( 'erstelle Dashlet', e.target.getAttribute('data-position') );
        var dashletPosition = e.target.getAttribute('data-position');

        var dashlets = this.state.dashlets;
        dashlets.splice(dashletPosition, 1, this.state.dashlets[dashletPosition],{name: 'Neues Dashlet', typ: 'statistic'});
        console.log( dashlets );
        this.setState( {dashlets: dashlets} );
    }

    render() {

        var self = this;

        return (
            <div className="finance">
                <div className="header-finance">
                    <Button onClick={this.eventSubYear.bind(self)} className="lastYear btn-info"><a className="glyphicon glyphicon-backward"></a> Jahr--</Button>
                    {this.state.year}
                    <Button onClick={this.eventAddYear.bind(self)} className="nextYear btn-info">Jahr++ <a className="glyphicon glyphicon-forward"></a></Button>
                </div>

                <div className="dashlet-container">

                    {this.state.dashlets.map( ($dashlet, $iterator) => {
                        return <Dashlet
                                    name={$dashlet.name}
                                    typ={$dashlet.type}
                                    dashletType={$dashlet.typeDetail}
                                    fnChangeTyp={this.eventChangeTyp.bind(self)}
                                    fnChangeName={this.eventChangeName.bind(self)}
                                    fnChangeSize={this.eventChangeSize.bind(self)}
                                    fnChangeSQL={this.eventChangeSQL.bind(self)}
                                    fnDeleteDashlet={this.eventDeleteDashlet.bind(self)}
                                    fnCreatDashlet={this.eventCreateDashlet.bind(self)}
                                    mode={this.props.mode}
                                    year={this.state.year}
                                    key={$iterator}
                                    pos={$iterator}
                                    sql={$dashlet.sql ? $dashlet.sql : ''}
                                    size={$dashlet.size ? $dashlet.size : 1}>

                            </Dashlet>
                    })}

                </div>
            </div>
        );
    }
}

export default Finance;
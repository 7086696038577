import React from 'react';
import styled from 'styled-components';
import colors from './colors';

const PanelOldSchool = styled.div`
	
    width: ${ props => props.width || '100%'};
    background-color: rgba( 100,100,100, .6 );
    margin: auto;
    padding: 30px;
    
    border-style: none;
    border-radius: 5px;
    font-family: ${colors.font.family}
    color: ${colors.font.color};
    
    box-shadow: 2px 3px rgba(0,0,0,0.5);
`;

export default PanelOldSchool;
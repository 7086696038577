import React, {Component} from 'react';
import axios from 'axios';
import {getBackendAdress,errorMessage, NotificationManager} from '../tools';

import './usersettings.css';
import Moment from "react-moment/dist/index";

var moment = require('moment');
Moment.globalLocale = 'de';
Moment.globalFormat = 'DD MMM YYYY HH:mm';

class Usersettings extends Component {


    constructor(props) {
        super(props);

        this.state = {
            username: this.getUsername(),
            lastLogin: null,
            lastIP: null,
            currentIP: null,
            nickname: null
        };
        this.getLastLogin();
    }


    componentWillReceiveProps(nextProps) {
        this.getLastLogin();
    }

    getUsername(){
        var username = '';

        document.cookie.split(";").map( (item) => {
            if( item.includes("user=") ){
                username = item.replace("user=", "").trim();
            }
        });

        return username === '' ? null : username;
    }

    getLastLogin(){
        var $self = this;

        axios.get( getBackendAdress( this.props.mode ) + 'php_login.php?type=getInformation&getLastLogin='+this.state.username )
            .then( function (response) {
                $self.setState({lastLogin: response.data.lastLogin});
                $self.setState({lastIP: response.data.lastIP});
                $self.setState({currentIP: response.data.currentIP});
                $self.setState({nickname: response.data.nickname});
                document.getElementById("nickname").value = response.data.nickname;
            });
    }

    submitChanges( ){

        if( ! (this.submitNewInformation() || this.submitNewPassword() ) ){
            NotificationManager.error( "Keine Änderungen vorhanden" );
        }
    }

    submitNewInformation(){
        if( document.getElementById("nickname").value === this.state.nickname ){
            return false;
        } else {

            let $self = this;
            let nickname = document.getElementById("nickname").value;

            axios.get( getBackendAdress( this.props.mode ) + 'php_login.php?type=changeInformation&username=' + this.state.username + '&nickname=' + nickname )
                .then(function (response) {

                    if (response.data === true) {

                        NotificationManager.success('erfolgreich');
                        $self.setState({nickname: nickname});
                    } else {

                        NotificationManager.error('fehlgeschlagen');
                    }
                });
            return true;
        }
    }
    submitNewPassword(){

        let password_01 = document.getElementById("pwd1").value;
        let password_02 = document.getElementById("pwd2").value;

        if( password_01.length === 0 && password_02.length === 0 ){
            return false;
        } else {

            if (password_01 === password_02 && password_01.length > 0) {

                var md5 = require('md5');

                axios.get( getBackendAdress( this.props.mode ) + 'php_login.php?type=saveNewPassword&username=' + this.state.username + '&newPW=' + md5(password_01))
                    .then(function (response) {

                        if (response.data === true) {

                            NotificationManager.success('erfolgreich', 'Passwort speichern');
                            document.getElementById("pwd1").value = "";
                            document.getElementById("pwd2").value = "";

                        } else {

                            NotificationManager.error('fehlgeschlagen', 'Passwort speichern');
                        }
                    });
            } else {
                NotificationManager.error('Passwörter sind unterschiedlich');
            }

            return true;
        }
    }

    render() {

        return (<div className="usersettingsPage">
            <div className="row" id="login">
                <div className="col-md-5">Login:</div>
                <input type="text"
                       disabled={true}
                       className="col-md-6"
                       value={this.state.username == undefined ? '** unbekannt **' : this.state.username} />
            </div>
            <div className="row" id="nickname">
                <div className="col-md-5">NickName:</div>
                <input type="text"
                       className="col-md-6"
                       value={this.state.nickname == undefined ? '** unbekannt **' : this.state.nickname} />
            </div>
            <div className="row" id="lastip">
                <div className="col-md-5">Letzte IP:</div>
                <div className="col-md-6">
                    <div>{this.state.lastIP} (aktuell: {this.state.currentIP})</div>
                </div>
            </div>
            <div className="row" id="lastlogin">
                <div className="col-md-5">Letzter Login:</div>
                <div className="col-md-6">
                    <Moment format="DD. MMMM YYYY HH:mm:ss">{this.state.lastLogin}</Moment>
                </div>
            </div>

            <div className="row" id="password">
                <div className="col-md-5">Passwort:</div>
                <input id="pwd1" type="password" className="col-md-6"></input>
            </div>
            <div className="row" id="password_repeat">
                <div className="col-md-5">Passwort (wiederholen):</div>
                <input id="pwd2" type="password" className="col-md-6"></input>
            </div>
            <div className="btn btn-success"
                 onClick={this.submitChanges.bind( this )}
            >Übernehmen</div>
        </div>);
    }
}

export default Usersettings;
import React, {Component} from 'react';

import DashletDiagramm from './dashletDiagramm';
import DashletStatistic from './dashletStatistic';


class DashletContent extends Component {

    _flip () {
        this.setState ({ isFlipped: !this.state.isFlipped });
    }

    _renderFlipButton(){
        return (
            <div className="button-flipper-config">
                <div className="glyphicon glyphicon-cog" onClick={this.props.fnFlip}></div>
            </div>
        );
    }

    _getTitle(){

        if( this.props.isFront )
            return (<h1>{this.props.params.name}</h1>);
        else return (<h1>Konfiguration</h1>);
    }

    _getDashletContent( $type ){

        switch( $type ){
            case 'statistic':
                return <DashletStatistic
                    mode={this.props.mode}
                    fnChangeTyp={this.props.fnChangeTyp}
                    fnChangeName={this.props.fnChangeName}
                    fnChangeSize={this.props.fnChangeSize}
                    fnChangeSQL={this.props.fnChangeSQL}
                    fnDeleteDashlet={this.props.fnDeleteDashlet}
                    fnCreatDashlet={this.props.fnCreatDashlet}
                    pos={this.props.pos}
                    params={this.props} />;
            case 'diagramm':
                return <DashletDiagramm
                    mode={this.props.mode}
                    fnChangeTyp={this.props.fnChangeTyp}
                    fnChangeName={this.props.fnChangeName}
                    fnChangeSize={this.props.fnChangeSize}
                    fnChangeSQL={this.props.fnChangeSQL}
                    fnDeleteDashlet={this.props.fnDeleteDashlet}
                    fnCreatDashlet={this.props.fnCreatDashlet}
                    pos={this.props.pos}
                    params={this.props} />;
            default:
                return 'unbekannter Typ des Dashlets';
        }
    }

    _getDashletConfiguration(){

    }


    render() {

        var dashletContent = this._getDashletContent(  this.props.params.typ );

        return (
            <div className="dashletItem">
                { this._renderFlipButton() }
                { this._getTitle() }


                { (this.props.isFront && this.props.side === 'main') ||
                    (! this.props.isFront && this.props.side === 'configuration') ? dashletContent : '' }
            </div>
        );
    }
}

export default DashletContent;
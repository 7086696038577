import React, {Component} from 'react';
import CalendarOriginal from './../_calendar/calendar';
import axios from 'axios';
import {getBackendAdress, errorMessage} from '../../tools';

class Calendar extends Component {


    constructor(props) {
        super(props);
        this.state = {
            meetings: []
        };
    }

    componentWillReceiveProps(nextProps) {

        this._getAllDates( nextProps.mode );
    }


    componentDidMount() {

        this._getAllDates( this.props.mode );
    }

    _getAllDates() {

        var $self = this;

        switch ( this.props.mode ) {

            case 'offline':

                this.setState({meetings: [
                    {
                        day: 28,
                        month: 12,
                        year: 2017,
                        description: 'Vorstellungsgespräch bei Frima XY'
                    }]});
                break;


            default:

                axios.get( getBackendAdress( this.props.mode ) + 'php_candidatur.php?part=getDates')

                    .then(function (response) {

                        $self.setState({meetings: response.data});

                    }).catch(function (error) {
                        errorMessage(error, 'meetings');
                });
                break;
        }
    }

    render() {
        return (
            <CalendarOriginal
                mode={this.props.devMode}
                options={{
                    showHolidays: true,
                    meetings: this.state.meetings
                }}
            />);
    }
}

export default Calendar;
import React, {Component} from 'react';

import axios from "axios/index";
import {errorMessage, getBackendAdress} from "../../../tools";

import './keyValue.css';

class KeyValue extends Component {


    constructor(props) {
        super(props);

        this.state = {
            keyValue: []
        };

        this._getAllKeys( props.mode );
    }


    componentWillReceiveProps( nextProps ) {

        this._getAllKeys();
    }

    _getAllKeys(){

        var $self = this;

        switch( this.props.mode ) {

            case 'offline':
                break;

            default:

                axios.get( getBackendAdress( this.props.mode ) + 'php_keyvalue.php')

                    .then(function (response) {

                        $self.setState({keyValue: response.data});

                    }).catch(function (error) {
                    errorMessage(error, 'bookmarks');
                });
                break;
        }
    }

    renderKeyValueItems(){

        let items = [];

        this.state.keyValue.map( (item) => {
            items.push( this.renderKeyValueItem( item ) );
        });

        return items;
    }


    renderKeyValueItem( item ){
        return (<div className="row" key={item.name}>
            <div className="col-md-5">{ item.name }</div>
            <div className="col-md-5">{ this.decrypt( item.keyvalue, item.encrypted ) }</div>
            <div className="col-md-2">{ item.url ? <a href={item.url} target="_blank">öffnen</a> : "" }</div>
        </div>);
    }

    decrypt( keyvalue, isEncrypted ){

        if( isEncrypted && isEncrypted === "1" ) {

            let decrypted = '';

            keyvalue.split("").map((item) => {
                decrypted+= String.fromCharCode( item.charCodeAt(0) - 1 );
            });

            return decrypted;

        } else {
            return keyvalue;
        }
    }


    render(){
        return ( <div className="keyValuePage">
            <div className="row head">
                <div className="col-md-5">Name</div>
                <div className="col-md-5">Schlüssel</div>
                <div className="col-md-2"></div>
            </div>
            { this.renderKeyValueItems() }
        </div>);
    }
}

export default KeyValue;